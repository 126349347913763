/**
 * Returns a minimally functional empty contact for stubbing a component
 *
 */
import { ContactInfo } from '@/GeneratedTypes/Partner/ListInfo/ContactInfo'
import { NameToType } from '@/lib/support/models/IndividualPhone/data'

export function getEmptyContactInfo(): ContactInfo {
  return {
    id: 0,
    fullName: '',
    firstName: '',
    lastName: '',
    primaryPhone: '',
    primaryPhoneTypeID: NameToType('home'),
    secondaryPhone: '',
    secondaryPhoneTypeID: NameToType('mobile'),
    alternatePhone: '',
    alternatePhoneTypeID: NameToType('work'),
    emailAddress: '',
    emailValid: 'true',
    emailValidReason: '',
    isFullTime: false,
    trained: false,
    typePartnerRoleID: '',
    yearsExperience: 0,
    preferredContactDayOfWeek: null,
    preferredContactTimeOfDay: null,
    preferredContactMethod: null,
  }
}
