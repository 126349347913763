















import { defineComponent, ref, watch, PropType } from '@vue/composition-api'
import DayOfWeekPicker from '@/elements/DayOfWeekPicker.vue'
import { PlayerPracticeNightExclusion } from '@/GeneratedTypes/PlayerPracticeNightExclusion'
import {
  DayIndexToPracticeDays,
  PracticeDaysToDayIndex,
} from '@/views/Programs/Participants/ext/practicedayexclusiondata'

export default defineComponent({
  name: 'DayOfTheWeekParticipant',
  components: {
    DayOfWeekPicker,
  },
  props: {
    value: { type: Array as PropType<PlayerPracticeNightExclusion[]>, required: true, default: () => [] },
    daysAvailable: { type: Array as PropType<string[]>, required: true, default: () => [] },
    label: { type: String, required: true, default: '' },
  },
  setup(props, ctx) {
    const internalValue = ref<number | null>(null)

    watch(
      () => internalValue.value,
      (v: number | null) => {
        ctx.emit('input', DayIndexToPracticeDays(v))
      }
    )

    watch(
      () => props.value,
      (v: PlayerPracticeNightExclusion[]) => {
        const index = PracticeDaysToDayIndex(v)
        internalValue.value = index
      },
      { immediate: true }
    )

    return {
      internalValue,
    }
  },
})
