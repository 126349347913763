

































import { defineComponent, computed, ref, watch, onMounted, PropType } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import { getPaymentColumns } from '@/views/Programs/Participants/vues/ext/paymentgridcolumns'
import SimpleGrid from '@/elements/SimpleGrid.vue'
import PaymentAddModal from '@/components/PaymentAddModal.vue'
import { PlayerPayment } from '@/GeneratedTypes/PlayerPayment'
import { paymentNote, paymentSum } from '@/lib/support/models/PlayerPayment/builder'
import { GridColumn } from '@/models/Grid/GridColumn'
import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue'
import { UpwardPaymentMethodTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPaymentMethodTypeID'
import { decrementingPaymentSeed, getEmptyPlayerPayment } from '@/lib/support/models/PlayerPayment/data'

export default defineComponent({
  name: 'PaymentsDisplay',
  components: {
    PaymentAddModal,
    ConfirmDeleteModal,
    SimpleGrid,
  },
  props: {
    value: { type: Array as PropType<PlayerPayment[]>, required: true, default: () => [] },
    paymentTypes: { type: Array as PropType<UpwardPaymentMethodTypeID[]>, required: true, default: () => [] },
  },
  setup(props, ctx) {
    const showAddPayment = ref(false)
    const editPayment = ref<PlayerPayment>(getEmptyPlayerPayment(decrementingPaymentSeed()))

    const internalPayments = ref(props.value)

    const deleteConfirmShowing = ref(false)
    const deleteID = ref(0)

    watch(
      () => props.value,
      () => {
        internalPayments.value = cloneDeep(props.value)
      }
    )

    /**
     * Called by the modal on confirm, deleteID set by delete()
     */
    function deleteConfirmed() {
      internalPayments.value = internalPayments.value.filter((x) => x.paymentID != deleteID.value)
      input()
    }

    function edit(d: PlayerPayment) {
      editPayment.value = cloneDeep(
        internalPayments.value.find((x) => x.paymentID == d.paymentID) ??
          getEmptyPlayerPayment(decrementingPaymentSeed())
      )
      showAddPayment.value = true
    }

    function deletePayment(d: PlayerPayment) {
      deleteID.value = d.paymentID
      deleteConfirmShowing.value = true
    }
    const columns = ref<GridColumn[] | null>(null)

    onMounted(() => {
      columns.value = getPaymentColumns(edit, deletePayment)
    })

    function input() {
      ctx.emit('input', internalPayments.value)
    }

    const paymentMap = computed(() =>
      internalPayments.value.map((x) => ({
        date: x.paymentDate,
        type: x.typePaymentMethodID,
        amount: paymentSum(x),
        note: paymentNote(x),
        paymentID: x.paymentID,
      }))
    )

    function addNewPayment(payment: PlayerPayment) {
      const paymentIndex = internalPayments.value.findIndex((x) => x.paymentID == payment.paymentID)
      if (paymentIndex >= 0) {
        internalPayments.value.splice(paymentIndex, 1, payment)
      } else {
        internalPayments.value.push(payment)
      }
      input()
    }

    function addPayment() {
      editPayment.value = getEmptyPlayerPayment(decrementingPaymentSeed())
      showAddPayment.value = true
    }

    return {
      paymentMap,
      columns,
      editPayment,
      showAddPayment,
      deleteConfirmShowing,
      addPayment,
      addNewPayment,
      deleteConfirmed,
    }
  },
})
