import { PlayerContact } from '@/GeneratedTypes/PlayerContact'
import { PhoneTypeEnum } from '@/lib/support/models/IndividualPhone/data'
import { getPhoneByType } from '@/lib/support/models/PlayerContact/phoneutils'

export enum ContactValidationEnum {
  NOT_COMPLETE,
  BASIC_CONTACT, //meets the
  EMERGENCY_CONTACT, //meets emergency contact requirements
  FULL_CONTACT, //meets initial contact entry requirements
  FULL_EMERGENCY_CONTACT, //meets both emergency and full contact criteria
}

export function isFullContactValidationType(v: ContactValidationEnum) {
  return v == ContactValidationEnum.FULL_CONTACT || v == ContactValidationEnum.FULL_EMERGENCY_CONTACT
}

export function isEmergencyValidationType(v: ContactValidationEnum) {
  return v == ContactValidationEnum.EMERGENCY_CONTACT || v == ContactValidationEnum.FULL_EMERGENCY_CONTACT
}

/**
 * Contact Validation for various classes of contact. Used by the forms that
 * process player contacts.
 * @param c
 */
export function getContactValidationType(c: PlayerContact): ContactValidationEnum {
  let ct = ContactValidationEnum.NOT_COMPLETE
  if (c.lastName && c.firstName) {
    ct = ContactValidationEnum.BASIC_CONTACT
    if (
      c.isEmergencyContact &&
      //c.emailAddresses?.length && //4651
      //c.emailAddresses[0].emailAddress && //4651
      (!!getPhoneByType(c, PhoneTypeEnum.HOME).phoneNumber ||
        !!getPhoneByType(c, PhoneTypeEnum.MOBILE).phoneNumber)
    ) {
      ct = ContactValidationEnum.EMERGENCY_CONTACT
    }
    ct =
      ct == ContactValidationEnum.EMERGENCY_CONTACT
        ? ContactValidationEnum.FULL_EMERGENCY_CONTACT
        : ContactValidationEnum.FULL_CONTACT
  }

  return ct
}
