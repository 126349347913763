











































import { defineComponent, ref, watch, computed, PropType } from '@vue/composition-api'
import { getProps, useModalValue } from '@/composables/ModalValue'
import { cloneDeep } from 'lodash'
import Modal from './Modal.vue'
import SimpleGrid from '@/elements/SimpleGrid.vue'
import SelectInput from '@/elements/SelectInput.vue'

import TextInput from '@/elements/TextInput.vue'

import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import DateInput from '@/elements/DateInput.vue'
import { PlayerPayment } from '@/GeneratedTypes/PlayerPayment'
import MemoInput from '@/elements/MemoInput.vue'
import { decrementingPaymentSeed, getEmptyPlayerPayment } from '@/lib/support/models/PlayerPayment/data'

import CurrencyInput from '@/elements/CurrencyInput.vue'
import { composePayment, paymentNote, paymentSum } from '@/lib/support/models/PlayerPayment/builder'
import { UpwardPaymentMethodTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPaymentMethodTypeID'

export default defineComponent({
  name: 'PaymentAddModal',
  components: {
    CurrencyInput,
    MemoInput,
    DateInput,
    VeeValidateForm,
    SelectInput,
    TextInput,
    Modal,
    SimpleGrid,
  },
  props: {
    payment: {
      type: Object as PropType<PlayerPayment>,
      default: () => getEmptyPlayerPayment(decrementingPaymentSeed()),
    },
    paymentTypes: { type: Array as PropType<UpwardPaymentMethodTypeID[]>, default: () => [], required: true },
    ...getProps(),
  },
  setup(props, ctx) {
    const { isModalOpen, closeModal } = useModalValue(props, ctx)

    const internalPayment = ref<PlayerPayment>(getEmptyPlayerPayment(decrementingPaymentSeed()))
    const note = ref('')
    const amount = ref(0)
    const type = ref('')
    const date = ref<Date>(new Date())

    watch(
      () => props.payment,
      () => {
        decomposePayment()
      },
      { deep: true, immediate: true }
    )

    const paymentRecord = computed(
      (): PlayerPayment =>
        composePayment(internalPayment.value, amount.value, note.value, type.value, date.value)
    )

    function decomposePayment() {
      internalPayment.value = cloneDeep(props.payment)
      amount.value = paymentSum(internalPayment.value)
      note.value = paymentNote(internalPayment.value)
      date.value = internalPayment.value.paymentDate ?? new Date()
      type.value = internalPayment.value.typePaymentMethodID
    }

    function paymentUpdated() {
      ctx.emit('payment-updated', paymentRecord.value)
    }

    function resetForm() {
      internalPayment.value = getEmptyPlayerPayment(decrementingPaymentSeed())
      decomposePayment()
    }

    function cancel() {
      resetForm()
      closeModal()
    }

    function confirmed() {
      const formRef = ctx.refs.form as HTMLFormElement
      if (formRef.reportValidity()) {
        paymentUpdated()
        closeModal()
        resetForm()
      }
    }

    return {
      isModalOpen,
      date,
      type,
      amount,
      note,
      cancel,
      confirmed,
    }
  },
})
