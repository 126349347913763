





















import { defineComponent, ref, watch, onMounted, PropType } from '@vue/composition-api'
import { getProps, useModalValue } from '@/composables/ModalValue'

import Modal from './Modal.vue'
import SimpleGrid from '@/elements/SimpleGrid.vue'
import PlayerEvaluationEditGrid from '@/components/PlayerEvaluationEditGrid.vue'

import { PlayerEvaluation } from '@/GeneratedTypes/PlayerEvaluation'

export default defineComponent({
  name: 'PlayerEvaluationEditModal',
  components: {
    Modal,
    SimpleGrid,
    PlayerEvaluationEditGrid,
  },
  props: {
    evaluation: { type: Array as PropType<PlayerEvaluation[]>, default: [] },
    ...getProps(),
  },
  setup(props, ctx) {
    const { isModalOpen, closeModal } = useModalValue(props, ctx)
    const internalEvaluation = ref<PlayerEvaluation[]>([])

    function setInternalEvaluation() {
      if (props.evaluation) {
        // create an immutable representation so we don't have bugs backtracking
        internalEvaluation.value = props.evaluation.map((x) => ({ ...x }))
      }
    }

    watch(
      () => props.evaluation,
      () => {
        setInternalEvaluation()
      }
    )

    onMounted(() => {
      setInternalEvaluation()
    })

    function cancel() {
      closeModal()
    }

    function confirmed() {
      const formRef = ctx.refs.form as HTMLFormElement
      if (formRef.reportValidity()) {
        ctx.emit('evaluationUpdated', internalEvaluation.value)
        closeModal()
      }
    }

    return {
      isModalOpen,
      internalEvaluation,
      cancel,
      confirmed,
    }
  },
})
