













































































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

import FullBody from '@/components/FullBody.vue'

import { namespace as participantStoreName, getterNames } from '@/store/participants'
import { namespace as leagueStoreName, getterNames as leagueGetterNames } from '@/store/leagues'
import { namespace as paymentTypesName, getterNames as paymentTypeGetterNames } from '@/store/paymentTypes'

import { namespace } from 'vuex-class'

const participants = namespace(participantStoreName)
const league = namespace(leagueStoreName)
const paymentTypesStore = namespace(paymentTypesName)

/**types */

import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
/**components */

import TextInput from '@/elements/TextInput.vue'

import Loading from '@/elements/Loading.vue'
import InputLabel from '@/elements/InputLabel.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import MemoInput from '@/elements/MemoInput.vue'
import YesNoInput from '@/elements/YesNoInput.vue'
import NumberInput from '@/elements/NumberInput.vue'

/** model support */
import SelectInput from '@/elements/SelectInput.vue'
import PaymentsDisplay from '@/views/Programs/Participants/vues/PaymentsDisplay.vue'
import EvaluationDisplay from '@/views/Programs/Participants/vues/EvaluationDisplay.vue'
import { LeaguePlayer } from '@/GeneratedTypes/LeaguePlayer'

import { PlayerProgram } from '@/GeneratedTypes/PlayerProgram'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'

import CoachSelect from '@/views/Programs/Participants/vues/CoachSelect.vue'

import {
  CoachFromPlayerProgram,
  EmptyCoachSelect,
  CoachSelect as CoachSelectModel,
} from '@/models/ParticipantUI/CoachSelect'
import { UpwardPaymentMethodTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPaymentMethodTypeID'
import { League } from '@/GeneratedTypes/League'
import CarpoolSelect from '@/views/Programs/Participants/vues/CarpoolSelect.vue'
import { getEmptyCarpoolLink } from '@/lib/support/models/PlayerCarpoolLink/data'
import volunteersClient from '@/clients/volunteersClient'
import { PlayerProduct } from '@/GeneratedTypes/PlayerProduct'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import SizeSelectorPanel from '@/components/SizeSelectorPanel/SizeSelectorPanel.vue'
import { productListToProductSelections } from '@/lib/support/components/SizeSelectorPanel/operations'
import { Identity } from '@/lib/support/components/SizeSelectorPanel/operations'

@Component({
  components: {
    SizeSelectorPanel,
    CarpoolSelect,
    VeeValidateForm,
    EvaluationDisplay,
    PaymentsDisplay,
    CoachSelect,
    SelectInput,
    Loading,
    TextInput,
    CheckboxInput,
    MemoInput,
    YesNoInput,
    NumberInput,
    InputLabel,
    FullBody,
  },
})
export default class ParticipantProgram extends Vue {
  @Prop({ type: Object, required: true })
  private readonly value!: PlayerProgram

  @Prop({ type: Array, required: true })
  readonly productItems!: ParticipantOrderProductInfo[]

  @Prop({ type: String, default: null, required: true })
  private readonly gender!: string | null

  @Prop({ type: String, default: '', required: true })
  private readonly upwId!: string

  @Prop({ type: Number, default: 0, required: true })
  private readonly individualID!: number

  @participants.Getter('playerLoading') loading!: boolean
  @participants.Getter('currentItem') readonly currentParticipant!: LeaguePlayerInfo
  @participants.Getter('participants') participants!: LeaguePlayerInfo[]
  @participants.Getter(getterNames.participantUnderEdit)
  readonly participantTemplate!: LeaguePlayer
  @league.Getter(leagueGetterNames.currentItem) selectedLeagueModel!: League

  @paymentTypesStore.Getter(paymentTypeGetterNames.allItems)
  readonly paymentTypes!: UpwardPaymentMethodTypeID[]

  private editProducts: PlayerProduct[] = []
  private coach = EmptyCoachSelect()
  private UIDisableOverride = true //see #3302

  /**
   * Determines if products are shown
   */
  get showingProducts() {
    return (
      this.productItems?.length &&
      this.editProducts?.length &&
      productListToProductSelections(
        this.editProducts,
        this.productItems,
        this.gender ?? '',
        Identity.PARTICIPANT
      ).length
    )
  }

  get canCoachLink() {
    return this.selectedLeagueModel.allowCoachLinks || this.UIDisableOverride
  }

  get canCarpoolLink() {
    return this.selectedLeagueModel.allowCarpoolLinks || this.UIDisableOverride
  }

  get carpoolLinkParticipants() {
    return this.participants.filter((x) => x.typeProgramID == this.value.typeProgramID)
  }

  @Watch('value', { immediate: true })
  async valueChanged() {
    this.value.evaluations = this.value.evaluations ?? []
    this.coach = CoachFromPlayerProgram(this.value)
    await this.updateCoachModel()
    this.value.payments = this.value.payments ?? []
    this.value.carpoolLink = this.value.carpoolLink ?? getEmptyCarpoolLink()
    this.editProducts = cloneDeep(this.value.products) ?? []
  }

  async updateCoachModel() {
    if ((this.coach.linkID || this.coach.pendingLinkID) && !this.coach.firstName && !this.coach.lastName) {
      //semi expensive coach name load

      const v = await volunteersClient.retrieveVolunteer(
        this.upwId,
        this.coach.linkID || this.coach.pendingLinkID
      )
      this.coach.firstName = v?.firstName ?? 'n/a'
      this.coach.lastName = v?.lastName ?? 'n/a'
    }
  }

  @Emit()
  coachLinkChanged(coach: CoachSelectModel) {
    return { coach: coach, typeProgramID: this.value.typeProgramID }
  }

  @Emit()
  productsChanged(p: PlayerProduct[]) {
    return { products: p, typeProgramID: this.value.typeProgramID }
  }
}
