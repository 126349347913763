
































import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'
import TextInput from '../../../../elements/TextInput.vue'
import { RolesEnum } from '@/lib/support/models/LeagueVolunteer/data'
import VolunteerSelectionModal from '@/components/VolunteerSelectionModal.vue'
import { CoachSelect, EmptyCoachSelect } from '@/models/ParticipantUI/CoachSelect'
import { VolunteerSearchInfo } from '@/GeneratedTypes/ListInfo/VolunteerSearchInfo'
import { cloneDeep } from 'lodash'

export default defineComponent({
  name: 'CoachSelect',
  components: {
    TextInput,
    VolunteerSelectionModal,
  },
  props: {
    value: { type: Object as PropType<CoachSelect>, default: () => EmptyCoachSelect() },
    league: { type: String, required: true, default: '' },
    typeProgramID: { type: String, required: false, default: 'NONE' },
  },
  setup(props, ctx) {
    const internalValue = ref<CoachSelect>(EmptyCoachSelect())
    const coachRole = ref(RolesEnum.COACH)
    const coachSelectionOpen = ref(false)
    const coachSearchTerm = ref('')

    /**
     * Coach has been selected by the select process.
     * @param coach
     */
    function coachSelected(coach: VolunteerSearchInfo) {
      volunteerFindToValueField(coach)
      input()
    }

    const initialRecord = computed(
      (): VolunteerSearchInfo => {
        const r = {
          firstName: '',
          lastName: '',
          individualID: 0,
          gender: '',
          birthDate: new Date(),
          firstAddress: '',
          firstEmailAddress: '',
          individualCreateDate: new Date(),
          leagueID: 0,
          leagueName: '',
          middleInitial: '',
          accountNumber: '',
          teams: [],
          upwardLeagueID: props.league,
        }

        if (internalValue.value.linkID) {
          r.firstName = internalValue.value.firstName
          r.lastName = internalValue.value.lastName
          r.individualID = internalValue.value.linkID
        }

        return r
      }
    )

    /**
     * Convert the selected coach to a coach selection.
     * @param coach
     */
    function volunteerFindToValueField(coach: VolunteerSearchInfo | null) {
      if (coach) {
        internalValue.value.linkID = coach.individualID
        internalValue.value.pendingLinkID = 0
        internalValue.value.firstName = coach.firstName ?? ''
        internalValue.value.lastName = coach.lastName ?? 'n/a'
      } else {
        internalValue.value = EmptyCoachSelect()
      }
    }

    function clear() {
      internalValue.value = EmptyCoachSelect()
      coachSelectionOpen.value = false
      input()
    }

    function input() {
      ctx.emit('input', internalValue.value)
    }

    /**
     * Open the coach selection dialog.
     */
    function coachSelect() {
      coachSearchTerm.value = internalValue.value.lastName

      coachSelectionOpen.value = true
    }

    function setInternalValue() {
      if (!props.value) {
        internalValue.value = EmptyCoachSelect()
      } else {
        internalValue.value = cloneDeep(props.value)
      }
    }

    watch(
      () => props.value,
      () => {
        setInternalValue()
      },
      { immediate: true, deep: true }
    )

    const shouldShowWarning = computed(() => isCoachLinkedPending.value)

    const isCoachLinkedPending = computed(() => {
      return (
        internalValue.value.linkID == 0 &&
        `${internalValue.value.firstName}${internalValue.value.lastName}`.length != 0
      )
    })

    return {
      coachRole,
      coachSearchTerm,
      initialRecord,
      coachSelectionOpen,
      shouldShowWarning,
      coachSelect,
      coachSelected,
      clear,
    }
  },
})
