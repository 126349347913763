


























































































































import { defineComponent, computed, ref, watch, onMounted, PropType } from '@vue/composition-api'

import Modal from './Modal.vue'
import SimpleGrid from '@/elements/SimpleGrid.vue'
import SelectInput from '@/elements/SelectInput.vue'
import { PlayerContact } from '@/GeneratedTypes/PlayerContact'
import {
  getEmptyPlayerContact,
  PlayerContactSeed,
  relationshipKVMap,
} from '@/lib/support/models/PlayerContact/data'
import TextInput from '@/elements/TextInput.vue'
import { getPhoneByType, setPhone } from '@/lib/support/models/PlayerContact/phoneutils'
import {
  getEmptyPhone,
  homePhoneSeed,
  mobilePhoneSeed,
  PhoneTypeEnum,
} from '@/lib/support/models/IndividualPhone/data'
import { IndividualPhone } from '@/GeneratedTypes/IndividualPhone'
import { IndividualEmail } from '@/GeneratedTypes/IndividualEmail'
import { getEmptyIndividualEmail } from '@/lib/support/models/IndividualEmail/data'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'

import PhoneInputMask from '@/elements/PhoneInputMask.vue'
import ParticipantEdit from '@/views/Programs/Participants/ParticipantEdit.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import { getFirstEmail, setFirstEmail } from '@/lib/support/models/IndividualEmail/email_list_operations'
import AddressRowBlock from '@/components/AddressRowBlock.vue'
import { IndividualAddress } from '@/GeneratedTypes/IndividualAddress'
import { getEmptyIndividualAddress } from '@/lib/support/models/IndividualAddress/data'
import { replaceOrInsertAddress } from '@/views/Programs/Participants/ext/composeutils'
import { cloneDeep, isEqual } from 'lodash'
import {
  getContactValidationType,
  isEmergencyValidationType,
  isFullContactValidationType,
} from '@/lib/support/models/PlayerContact/validation_utils'
import { useFeatureFlags } from '@/services/useFeatureFlags'
import store from '@/store'
import emailServiceClient from '@/clients/emailServiceClient'
import { IndividualEmailCheck } from '@/models/IndividualEmailCheck'

export default defineComponent({
  name: 'ContactEditForm',
  components: {
    AddressRowBlock,
    CheckboxInput,
    ParticipantEdit,
    PhoneInputMask,
    VeeValidateForm,
    SelectInput,
    TextInput,
    Modal,
    SimpleGrid,
  },
  props: {
    value: { type: Object as PropType<PlayerContact>, default: getEmptyPlayerContact },
  },
  setup(props, ctx) {
    const disabled = ref(false)
    const internalContact = ref<PlayerContact>(getEmptyPlayerContact(new PlayerContactSeed()))

    const homePhone = ref<IndividualPhone>(getEmptyPhone(homePhoneSeed()))
    const mobilePhone = ref<IndividualPhone>(getEmptyPhone(mobilePhoneSeed()))
    const email = ref<IndividualEmail>(getEmptyIndividualEmail())
    const isEmergencyContact = ref(false)
    const editAddress = ref<IndividualAddress>(getEmptyIndividualAddress())

    const emailAddress = ref('')
    const firstName = ref('')
    const lastName = ref('')
    const typeRelationshipID = ref('')

    const optOutEmails = ref(false)
    const optOutSMS = ref(false)

    const validEmail = ref(true)
    const disableEmail = ref(false)

    const guardianTypes = ref(relationshipKVMap())

    // quick guard of this component.
    onMounted(() => {
      if (!store.getters.authorization.isImpersonated) {
        disabled.value = true
      }
    })

    function input() {
      optOutSMS.value = composedContact.value.optOutSMS //set model value since there is not a input field
      ctx.emit('input', composedContact.value)
    }

    function update() {
      ctx.emit('', composedContact.value)
    }

    async function emailupdated() {
      await onEmailConfirm()

      validEmail.value = true
      ctx.emit('emailupdated', composedContact.value)
    }

    const composedContact = computed(
      (): PlayerContact => {
        let c = {
          ...internalContact.value,
          firstName: firstName.value,
          lastName: lastName.value,
          typeRelationshipID: typeRelationshipID.value,
        }

        c = setPhone(c, mobilePhone.value)
        c = setPhone(c, homePhone.value)
        if (emailAddress.value) {
          c = setFirstEmail(c, {
            ...email.value,
            emailAddress: emailAddress.value,
            valid: validEmail.value.toString(),
          })
        } else {
          c.emailAddresses = []
        }

        c.isEmergencyContact = isEmergencyContact.value
        if ((editAddress.value.street1 && editAddress.value.subdivision2) || editAddress.value.addressID) {
          c.addresses = replaceOrInsertAddress(props.value.addresses || [], editAddress.value)
        }
        c.optOutEmails = optOutEmails.value
        c.optOutSMS = optOutSMS.value

        return c
      }
    )

    function decomposeContact() {
      ;({
        firstName: firstName.value,
        lastName: lastName.value,
        typeRelationshipID: typeRelationshipID.value,
        isEmergencyContact: isEmergencyContact.value,
        optOutEmails: optOutEmails.value,
        optOutSMS: optOutSMS.value,
      } = internalContact.value)
      editAddress.value = internalContact.value.addresses?.length
        ? internalContact.value.addresses[0]
        : getEmptyIndividualAddress()
      homePhone.value = getPhoneByType(internalContact.value, PhoneTypeEnum.HOME)
      mobilePhone.value = getPhoneByType(internalContact.value, PhoneTypeEnum.MOBILE)
      email.value = getFirstEmail(internalContact.value) || getEmptyIndividualEmail()
      emailAddress.value = email.value?.emailAddress || ''
      validEmail.value = isNullOrEmpty(email.value?.valid)
        ? true
        : email.value?.valid.toLowerCase() === 'true'
    }

    function isNullOrEmpty(val: any) {
      if (!val) return true

      if (val.length == 0) return true

      return false
    }

    async function onEmailConfirm() {
      try {
        //run email address through auto-correction
        if (emailAddress.value) {
          const correctedEmail = await emailServiceClient.getCorrectedEmail({
            email: emailAddress.value,
          } as IndividualEmailCheck)

          if (correctedEmail) {
            emailAddress.value = correctedEmail
            return
          }
        }
      } catch {
        // Ignore these errors since this is more of a convenience feature and not required to work
        // to register successfully
      }
    }

    const meetsEmergencyCriteria = computed(() =>
      isEmergencyValidationType(getContactValidationType(composedContact.value))
    )

    const meetsFullCriteria = computed(() =>
      isFullContactValidationType(getContactValidationType(composedContact.value))
    )

    watch(
      () => props.value,
      () => {
        if (props.value && !isEqual(internalContact.value, props.value)) {
          internalContact.value = cloneDeep(props.value)
          decomposeContact()
        }
      },
      { deep: true, immediate: true }
    )

    return {
      ...useFeatureFlags(),
      disabled,
      isEmergencyContact,
      meetsEmergencyCriteria,
      meetsFullCriteria,
      typeRelationshipID,
      guardianTypes,
      input,
      update,
      firstName,
      lastName,
      homePhone,
      mobilePhone,
      emailAddress,
      emailupdated,
      disableEmail,
      validEmail,
      optOutEmails,
      optOutSMS,
      editAddress,
    }
  },
})
