/***
 * Converts a league practice day exclusion into the index for the day of week
 * control, undefined if no practice day
 * @param pne
 */
import { UpwardIDToNumber } from '@/lib/support/models/UpwardTypes/UpdwardDayTypeID/UpwardIDToNumber'
import { PlayerPracticeNightExclusion } from '@/GeneratedTypes/PlayerPracticeNightExclusion'
import { NumberToUpwardID } from '@/lib/support/models/UpwardTypes/UpdwardDayTypeID/NumberToUpwardID'

/**
 * Returns a representation of practice day exclusion for a player to the control
 *
 * @param pne - practice day array
 */
export function PracticeDaysToDayIndex(pne: PlayerPracticeNightExclusion[]) {
  if (pne.length) {
    return UpwardIDToNumber(pne[0].typeDayID)
  }

  return null
}

/**
 * Returns a representation of practice day exclusion for a player to the control
 *
 * @param dayindex: number
 * @return {PlayerPracticeNightExclusion[]} valid practice night exclusion array
 */
export function DayIndexToPracticeDays(dayindex: number | null): PlayerPracticeNightExclusion[] {
  if (dayindex != null) {
    return [
      {
        typeDayID: NumberToUpwardID(dayindex),
      },
    ]
  }
  return []
}
