/***
 * Converts Sunday to 0, Monday to 1, etc... based on the UpwardID
 * @constructor
 */
import { ArgumentRuntimeException } from '@/lib/common/exceptions/ArgumentRuntimeException'

export function NumberToUpwardID(ID: number): string {
  switch (ID) {
    case 0:
      return 'Sunday'
    case 1:
      return 'Monday'
    case 2:
      return 'Tuesday'
    case 3:
      return 'Wednesday'
    case 4:
      return 'Thursday'
    case 5:
      return 'Friday'
    case 6:
      return 'Saturday'
  }
  throw new ArgumentRuntimeException(`NumberToUpwardID, ${ID} not valid for a weekday`)
}
