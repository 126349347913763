






















import { defineComponent, ref, computed, watch, PropType } from '@vue/composition-api'
import { getProps, useModalValue } from '@/composables/ModalValue'
import Modal from './Modal.vue'
import { PlayerContact } from '@/GeneratedTypes/PlayerContact'
import { getEmptyPlayerContact, PlayerContactSeed } from '@/lib/support/models/PlayerContact/data'

import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import { cloneDeep } from 'lodash'
import ContactEditForm from '@/components/ContactEditForm.vue'
import {
  ContactValidationEnum,
  getContactValidationType,
  isEmergencyValidationType,
} from '@/lib/support/models/PlayerContact/validation_utils'

export default defineComponent({
  name: 'ContactEditModal',
  components: {
    ContactEditForm,
    VeeValidateForm,
    Modal,
  },
  props: {
    contact: { type: Object as PropType<PlayerContact>, default: getEmptyPlayerContact },
    ...getProps(),
  },
  setup(props, ctx) {
    const { isModalOpen, closeModal } = useModalValue(props, ctx)

    const internalContact = ref<PlayerContact>(getEmptyPlayerContact(new PlayerContactSeed()))

    const meetsEmergencyCriteria = computed(() =>
      isEmergencyValidationType(getContactValidationType(internalContact.value))
    )

    const meetsBasicCriteria = computed(
      () => getContactValidationType(internalContact.value) != ContactValidationEnum.NOT_COMPLETE
    )

    const meetsSubmitCriteria = computed(() => {
      if (internalContact.value.isEmergencyContact) {
        return meetsEmergencyCriteria.value
      }
      return meetsBasicCriteria.value
    })

    watch(
      () => props.contact,
      () => {
        if (props.contact) {
          internalContact.value = cloneDeep(props.contact)
        }
      },
      { deep: true, immediate: true }
    )

    function cancel() {
      closeModal()
    }

    function confirmed() {
      if (meetsSubmitCriteria.value) {
        ctx.emit('contactUpdated', internalContact.value)
        closeModal()
      }
    }

    return {
      isModalOpen,
      internalContact,
      cancel,
      meetsSubmitCriteria,
      confirmed,
    }
  },
})
