


















































import { defineComponent, computed, PropType } from '@vue/composition-api'
import { ContactInfo } from '@/GeneratedTypes/Partner/ListInfo/ContactInfo'
import { getEmptyContactInfo } from '@/lib/support/models/ContactInfo/data'
import { formatPhoneNumber } from '@/services/phoneNumberService'

export default defineComponent({
  name: 'InnerContact',
  props: {
    contact: { type: Object as PropType<ContactInfo>, required: true, default: getEmptyContactInfo() },
  },
  setup(props) {
    function getPhoneIcon(phoneType: string | null) {
      switch (phoneType) {
        case 'HOME':
          return 'phone-icon fa fa-home'
        case 'MOBILE':
          return 'phone-icon fa fa-mobile-alt'
        default:
          return 'phone-icon fa fa-phone'
      }
    }

    const validEmail = computed(() => {
      if (props.contact) {
        return isNullOrEmpty(props.contact.emailValid)
          ? true
          : (props.contact.emailValid ?? 'true').toLowerCase() === 'true'
      }

      return true
    })

    function isNullOrEmpty(value: any) {
      if (!value) return true

      if (value.length == 0) return true

      return false
    }

    const sysGenEmailAddress = computed(() => {
      var status = false

      if (props.contact.emailAddress) {
        status =
          props.contact.emailAddress.indexOf('@email.onupward.one') > 0 ||
          props.contact.emailAddress.indexOf('@onupward.gen') > 0
      }

      return status
    })

    const primaryPhoneIcon = computed(() => getPhoneIcon(props.contact.primaryPhoneTypeID))

    const secondaryPhoneIcon = computed(() => getPhoneIcon(props.contact.secondaryPhoneTypeID))

    const primaryPhoneFormatted = computed(() => {
      if (!props.contact.primaryPhone) {
        return ''
      }

      return formatPhoneNumber(props.contact.primaryPhone)
    })

    const secondaryPhoneFormatted = computed(() => {
      if (!props.contact.secondaryPhone) {
        return ''
      }

      return formatPhoneNumber(props.contact.secondaryPhone)
    })

    return {
      primaryPhoneIcon,
      primaryPhoneFormatted,
      secondaryPhoneIcon,
      secondaryPhoneFormatted,
      sysGenEmailAddress,
      validEmail,
    }
  },
})
