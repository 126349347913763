import { PlayerProgram } from '@/GeneratedTypes/PlayerProgram'

export interface CoachSelect {
  firstName: string
  lastName: string
  pendingLinkID: number
  linkID: number
}

export function EmptyCoachSelect(): CoachSelect {
  return {
    firstName: '',
    lastName: '',
    linkID: 0,
    pendingLinkID: 0,
  }
}

export function CoachFromPlayerProgram(pp: PlayerProgram): CoachSelect {
  return {
    firstName: pp.pendingCoachLinkFirstName ?? '',
    lastName: pp.pendingCoachLinkLastName ?? '',
    pendingLinkID: pp.pendingCoachLinkIndividualID,
    linkID: pp.coachLinkIndividualID,
  }
}
