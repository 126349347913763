

































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { cloneDeep, intersection } from 'lodash'

import FullBody from '@/components/FullBody.vue'

import { namespace as participantStoreName, getterNames, mutationNames } from '@/store/participants'
import { namespace as leagueStoreName, getterNames as leagueGetterNames } from '@/store/leagues'
import { namespace as campStoreName, getterNames as campGetterNames } from '@/store/camps'
import {
  namespace as programTypeStoreName,
  getterNames as programTypeGetterNames,
} from '@/store/programTypes'

import { namespace } from 'vuex-class'

const participants = namespace(participantStoreName)
const league = namespace(leagueStoreName)
const camp = namespace(campStoreName)
const programTypesStore = namespace(programTypeStoreName)

/**types */

import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import store from '@/store'
/**components */

import TextInput from '@/elements/TextInput.vue'

import Loading from '@/elements/Loading.vue'
import InputLabel from '@/elements/InputLabel.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import MemoInput from '@/elements/MemoInput.vue'
import YesNoInput from '@/elements/YesNoInput.vue'
import Alert, { AlertTypeEnum } from '@/components/Alert.vue'

/** model support */
import { LeaguePlayerSeedInfo, PlayerInfoIDType } from '@/lib/support/models/LeaguePlayerInfo/data'
import { reconcileTemplate } from '@/views/Programs/Participants/ext/decomposeutils'
import SelectInput from '@/elements/SelectInput.vue'
import { getEmptyLeaguePlayer, IsNewPlayer } from '@/lib/support/models/LeaguePlayer/data'
import { LeaguePlayer } from '@/GeneratedTypes/LeaguePlayer'

import DayOfTheWeekParticipant from './vues/DayOfTheWeekParticipant.vue'

import { decomposeLeaguePlayer } from '@/views/Programs/Participants/ext/decomposeutils'
import ParticipantsContacts from '@/views/Programs/Participants/vues/ParticipantsContacts.vue'
import ParticipantSearch from '@/components/ParticipantSearch.vue'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import CheckboxGroupInput from '@/elements/CheckboxGroupInput.vue'

import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue'
import ParticipantBasics from '@/views/Programs/Participants/vues/ParticipantBasics.vue'
import ParticipantProgram from '@/views/Programs/Participants/vues/ParticipantProgram.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'

import { PlayerContact } from '@/GeneratedTypes/PlayerContact'

import { PlayerPracticeNightExclusion } from '@/GeneratedTypes/PlayerPracticeNightExclusion'
import { RuntimeException } from '@/lib/common/exceptions/RuntimeException'

import { updateTemplateWithEditsNew } from '@/views/Programs/Participants/ext/composeutils'
import { CoachSelect } from '@/models/ParticipantUI/CoachSelect'
import { PlayerUDFValue } from '@/GeneratedTypes/PlayerUDFValue'
import { getEmptyPlayerUDFs } from '@/lib/support/models/PlayerUDFValue/data'
import UDFPanel from '@/components/UDFPanel.vue'
import { League } from '@/GeneratedTypes/League'
import { useFeatureFlags } from '@/services/useFeatureFlags'
import CarpoolSelect from '@/views/Programs/Participants/vues/CarpoolSelect.vue'
import volunteersClient from '@/clients/volunteersClient'
import teamsClient from '@/clients/teamsClient'
import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'
import { PlayerContactToLeagueVolunteer } from '@/lib/support/models/LeagueVolunteer/PlayerContactToLeagueVolunteer'
import { composeLeagueVolunteer } from '@/views/Programs/Volunteers/ext/composeutils'
import { getEmptyAddress } from '@/lib/support/models/IndividualAddressInfo/data'
import { IndividualAddress } from '@/GeneratedTypes/IndividualAddress'
import ordersClient from '@/clients/ordersClient'
import { PlayerProduct } from '@/GeneratedTypes/PlayerProduct'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import SizeSelectorPanel from '@/components/SizeSelectorPanel/SizeSelectorPanel.vue'
import { ContactRoleEditType } from '@/models/ParticipantUI/ContactRoleEditType'
import { Genders } from '@/lib/support/models/MiscMappings/Gender'
import { LeagueAccount } from '@/GeneratedTypes/LeagueAccount'
import { Camp } from '@/GeneratedTypes/Camp'
import participantsClient from '@/clients/participantsClient'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { sortGrades, getGradesListFromAccount } from '@/services/gradeService'

class ParticipantEditException extends RuntimeException {
  name = 'Participant Edit Error'
}

@Component({
  setup() {
    return { ...useFeatureFlags() }
  },
  beforeRouteLeave(route, redirect, next) {
    const self = this as ParticipantEdit
    self.clearParticipant()
    next()
  },
  components: {
    SizeSelectorPanel,
    CarpoolSelect,
    VeeValidateForm,
    ParticipantBasics,
    ParticipantProgram,
    ConfirmDeleteModal,
    ParticipantSearch,
    RadioGroupInput,
    CheckboxGroupInput,
    DayOfTheWeekParticipant,
    SelectInput,
    Loading,
    TextInput,
    CheckboxInput,
    MemoInput,
    YesNoInput,
    InputLabel,
    ParticipantsContacts,
    UDFPanel,
    FullBody,
    Alert,
  },
  methods: {
    IsNewPlayer,
  },
})
export default class ParticipantEdit extends Vue {
  /** props */
  @Prop({ type: String })
  private readonly id: string | undefined /* string id of participant to edit/copy undefined on new */
  /** type mirrors definition in store. */
  private loadProgramParticipants = store.dispatch.participants.loadProgramParticipants
  private setCurrentParticipantByID = store.dispatch.participants.setCurrentParticipantByID
  private getTemplate = store.dispatch.participants.getNewTemplate
  private getParticipant = store.dispatch.participants.getParticipant
  private upsert = store.dispatch.participants.upsertParticipant
  private setLastLeagueLoaded = store.commit.participants.setLastLeagueLoaded
  private setSkipParticipantRefresh = store.commit.participants.setSkipParticipantListRefresh
  /** getters */
  @participants.Getter('participants') participants!: LeaguePlayerInfo[]
  @participants.Getter('playerLoading') loading!: boolean

  /** getters */
  @league.Getter(leagueGetterNames.currentItem) selectedLeagueModel!: League
  @camp.Getter(campGetterNames.currentItem) selectedCampModel!: Camp
  @participants.Getter('currentItem') readonly currentParticipant!: LeaguePlayerInfo
  @participants.Getter(getterNames.participantUnderEdit)
  readonly participantTemplate!: LeaguePlayer

  @participants.Getter(getterNames.currentProgram) readonly currentStoreProgram!: string
  @participants.Mutation(mutationNames.clearParticipant) clearParticipant!: () => void

  @programTypesStore.Getter(programTypeGetterNames.allItems)
  readonly programTypes!: UpwardProgramTypeID[]

  private UIDisableOverride = true //see #3302
  private carpoolSelectionOpen = false
  private newPlayerTemplate: LeaguePlayer = getEmptyLeaguePlayer()
  private selectedProgramTab = 0
  private readonly alertType = AlertTypeEnum.NOTICE

  /**
   * wake up and grab some definitions
   */
  async mounted() {
    await store.dispatch.paymentTypes.fetchAll(false) //get these cached up

    // complete reference to store binding on mount
    this.loadProgramParticipants = store.dispatch.participants.loadProgramParticipants
    this.setCurrentParticipantByID = store.dispatch.participants.setCurrentParticipantByID
    this.getTemplate = store.dispatch.participants.getNewTemplate
    this.getParticipant = store.dispatch.participants.getParticipant
    this.upsert = store.dispatch.participants.upsertParticipant
    this.setLastLeagueLoaded = store.commit.participants.setLastLeagueLoaded
    this.newPlayerTemplate = (await participantsClient.retrieveTemplate(this.upwId)) ?? getEmptyLeaguePlayer()
    await this.getProducts()
  }

  get shouldShowInlineFirstContact() {
    return IsNewPlayer(this.editParticipant) /*&& !completeContact(this.editContacts)*/
  }

  get editParticipantActivePrograms() {
    return this.editParticipant.programs?.filter((x) => x.active)
  }

  productItems: ParticipantOrderProductInfo[] = []

  /**
   * Returns the order list of things a participant needs to order
   */
  async getProducts() {
    this.productItems = (await ordersClient.getLeagueOrderItems(this.upwId)) ?? []
  }

  /**
   * Program can exclude a gender
   */
  get genderList() {
    //we must take the most restrictive case for the selected programs (if both are selected)
    let hasBoys = true
    let hasGirls = true
    const genders: any = {}
    if (this.accounts?.length) {
      const grades = this.accounts[0].grades
      if (grades) {
        this.programsToParticipate.forEach((x) => {
          hasBoys = hasBoys && grades.some((g) => g.typeProgramID === x && g.gender === Genders.male)
          hasGirls = hasGirls && grades.some((g) => g.typeProgramID === x && g.gender === Genders.female)
        })
        if (hasBoys) {
          genders['M'] = 'Male'
        }

        if (hasGirls) {
          genders['F'] = 'Female'
        }
      }
    }
    return genders
  }

  get accounts(): LeagueAccount[] | null {
    if (this.isCamp) return this.selectedCampModel.accounts
    return this.selectedLeagueModel.accounts
  }

  /**
   * Grades vary on gender and program
   */
  get gradesList() {
    //This needs to be the intersected set of grades for all programs selected
    const gender = this.editParticipant?.gender
    let retval: string[] = []

    if (gender && this.editParticipantActivePrograms && this.accounts && this.accounts.length > 0) {
      this.editParticipantActivePrograms.forEach((x) => {
        const progGrades = getGradesListFromAccount(this.accounts![0], x.typeProgramID, gender).map(
          (g) => g.upwardTypeID ?? ''
        )
        retval = retval.length == 0 ? progGrades : intersection(retval, progGrades)
      })
    }

    return retval.sort(sortGrades)
  }

  private editUdfs: PlayerUDFValue[] = getEmptyPlayerUDFs()

  get canUDF() {
    if (this.isCamp) {
      return !!this.selectedCampModel.playerUDFs && this.selectedCampModel.playerUDFs.length > 0
    }
    return !!this.selectedLeagueModel.playerUDFs && this.selectedLeagueModel.playerUDFs.length > 0
  }

  private get isCamp(): boolean {
    return this.$route.fullPath.indexOf('/camp/') >= 0
  }

  private contactRoleChanges: ContactRoleEditType[] = []
  storeContactRoles(v: ContactRoleEditType) {
    const i = this.contactRoleChanges.findIndex((x) => x.id == v.id)
    if (i >= 0) {
      this.contactRoleChanges.splice(i, 1, cloneDeep(v))
    } else {
      this.contactRoleChanges.push(cloneDeep(v))
    }
    this.spliceInRoleChanges(v)
  }

  private spliceInRoleChanges(v: ContactRoleEditType) {
    const i = this.playerContactVolunteers.findIndex((x) => x.individualID == v.id)
    if (i >= 0) {
      const c = cloneDeep(this.playerContactVolunteers[i])

      //if they are found, replace the model we got from API with the old model
      if (c) {
        this.playerContactVolunteers.splice(i, 1, {
          ...c,
          coachPreferences: v.coachPrefs,
          roles: v.roles,
          gender: v.gender,
        })
      }
    }
  }

  async updateVolunteerChanges() {
    for (const rolechange of this.contactRoleChanges) {
      //don't update someone that was created but then deleted in edit.
      if (this.editContacts.find((x) => x.individualID == rolechange.id)) {
        await this.updateContactRoles(rolechange)
      }
    }
    //we do the following so that volunteer edits under participant get reflected on volunteer tab load.
    store.commit.volunteers.forceVolunteerListReload()
  }

  /**
   * Called when a contact roles changes (primarily from PlayerContacts)
   * Currently this is real-time, we could save these guys until save on participant.
   *
   */
  async updateContactRoles({ id, roles, coachPrefs, gender }: ContactRoleEditType) {
    let editContactIndex = this.editContacts.findIndex((x) => x.individualID == id)

    //sanity check, a volunteer under edit in the interface *should* be in this array
    if (editContactIndex >= 0) {
      const ec = this.editContacts[editContactIndex]

      const c = cloneDeep(
        await volunteersClient.setVolunteerRoles(
          this.upwId,
          id,
          composeLeagueVolunteer(
            PlayerContactToLeagueVolunteer(this.editContacts[editContactIndex] ?? getEmptyLeaguePlayer()),
            {
              address: ec.addresses?.length ? ec.addresses[0] : (getEmptyAddress() as IndividualAddress),
              email: ec.emailAddresses?.length ? ec.emailAddresses[0].emailAddress : '',
              phones: ec.phoneNumbers ?? [],
              roles: roles,
              volunteer: {
                ...PlayerContactToLeagueVolunteer(
                  this.editContacts[editContactIndex] ?? getEmptyLeaguePlayer()
                ),
                gender: gender,
              },
              coachPreferences: coachPrefs,
            }
          ),

          roles,
          coachPrefs
        )
      )
      // contact will be returned.
      if (c) {
        // that contact should be found in our stack of preloaded-volunteers
        const i = this.playerContactVolunteers.findIndex((x) => x.individualID == id)
        //if they are found, replace the model we got from API with the old model
        if (i >= 0) {
          this.playerContactVolunteers.splice(i, 1, c)
        } else {
          //if not found by definition - if we are here we need to patch up the individual id of the new contact added.
          this.playerContactVolunteers.push(c)

          this.editContacts.splice(
            editContactIndex,
            1,
            cloneDeep({ ...ec, individualID: c.individualID } as PlayerContact)
          )
        }
      }
    } else {
      throw new ParticipantEditException(`Volunteer role assigned to non-loaded edit contact.`)
    }
  }

  get canPracticeNightExclude() {
    // we are short circuiting this for
    return (
      this.selectedLeagueModel.allowPracticeNightExclusions ||
      this.UIDisableOverride ||
      this.leagueDayList == []
    )
  }
  get leagueDayList() {
    return this.selectedLeagueModel?.practiceNights?.map((x) => x.typeDayID) ?? []
  }

  updateParticipant(l: LeaguePlayer) {
    //this.editParticipant = cloneDeep(l)
    this.editParticipant.firstName = l.firstName
    this.editParticipant.lastName = l.lastName
    this.editParticipant.middleInitial = l.middleInitial
    this.editParticipant.gender = l.gender
    this.editParticipant.typeGradeID = l.typeGradeID
    this.editParticipant.churchName = l.churchName
    this.editParticipant.birthDate = l.birthDate
    this.editParticipant.gradeIsOverridden = l.gradeIsOverridden
  }

  updateEditContacts(e: PlayerContact[]) {
    this.editContacts = e
  }

  get udfList() {
    if (this.isCamp) return this.selectedCampModel.playerUDFs
    return this.selectedLeagueModel.playerUDFs
  }

  /**
   * returns the programs that are under edit
   */
  get currentLeaguePrograms() {
    return this.programsToParticipate.map((p) =>
      this.isCamp
        ? this.selectedCampModel.programs?.find((x) => x.typeProgramID == p)
        : this.selectedLeagueModel.programs?.find((x) => x.typeProgramID == p)
    )
  }

  get programsToParticipate(): string[] {
    return this.editParticipantActivePrograms?.map((x) => x.typeProgramID) ?? []
  }

  private pendingProgramsToParticipate: string[] = []
  private confirmChangeProgram = false
  private movePaymentsonDelete = false
  private showMovePayments = false
  private showTeamWarning = false

  warnAboutProgramChange(v: string[]) {
    this.pendingProgramsToParticipate = cloneDeep(v)
    //invalid to choose less than one.
    if (v && v.length && v.length < (this.editParticipantActivePrograms?.length ?? 0)) {
      this.movePaymentsonDelete = false //default this to false before showing modal
      this.showMovePayments = false

      const oldProg = this.editParticipantActivePrograms?.find(
        (x) => x.typeProgramID != this.pendingProgramsToParticipate[0]
      )
      if (oldProg) {
        //block removing program if assigned to a team
        const team = this.teamInfo.find((x) => x.typeProgramID == oldProg.typeProgramID)
        if (team) {
          //add alert warning that player is assigned to a team
          this.showTeamWarning = true
          return
        } else {
          this.showMovePayments = oldProg.payments != null && oldProg.payments.length > 0
        }
      }

      //signal if the user is removing a program.
      this.confirmChangeProgram = true
    } else {
      this.changeProgram()
    }
  }

  changeProgram() {
    if (this.pendingProgramsToParticipate.length == 0) {
      return //sfety check, do nothiong since we always want at least one program selected
    }

    //we are either adding a new program or removing one
    if (this.pendingProgramsToParticipate.length == 1) {
      //remove the one that needs to be removed
      //copy payments over if they checked that option in the modal
      const oldProg = this.editParticipant.programs?.find(
        (x) => x.typeProgramID != this.pendingProgramsToParticipate[0]
      )
      this.editParticipant.programs =
        this.editParticipant.programs?.filter(
          (x) => x.typeProgramID == this.pendingProgramsToParticipate[0]
        ) ?? []
      if (this.movePaymentsonDelete && oldProg) {
        this.editParticipant.programs[0].payments = oldProg?.payments
        //reset payment and breakout IDs
        this.editParticipant.programs[0].payments?.forEach((x) => {
          x.paymentID = 0
          x.breakout?.forEach((b) => (b.breakoutID = 0))
        })
      }
    } else {
      //add the one that is missing
      this.pendingProgramsToParticipate.forEach((x) => {
        const prog = this.editParticipant.programs?.find((p) => p.typeProgramID == x)
        if (prog) {
          prog.active = true //re-activate it
        } else {
          const newProg = this.newPlayerTemplate.programs!.find((p) => p.typeProgramID == x)
          if (newProg) {
            this.editParticipant.programs?.push(newProg)
          }
        }
      })
    }
  }

  get programsAvailable(): UpwardProgramTypeID[] {
    if (this.newPlayerTemplate.programs?.length) {
      return this.newPlayerTemplate.programs.map(
        (x) => this.programTypes.find((p) => p.upwardTypeID == x.typeProgramID)!
      )
    } else {
      return []
    }
  }

  programDescription(typeProgramID: string) {
    let retval = ''
    const prog = this.programTypes.find((p) => p.upwardTypeID == typeProgramID)
    if (prog) {
      retval = prog.shortDescription ?? ''
    }

    return retval
  }

  /**
   * The route is based on how many arguments are passed.
   * new does not take a parameter so just replace the suggested route
   * with the new route.
   */
  relativeRoute(newRoute: string): string {
    if (this.editMode == 'new') {
      return newRoute
    }
    if (this.editMode == 'copy') {
      return '../../' + newRoute
    }
    return '../' + newRoute
  }

  /** private data */
  private editParticipant: LeaguePlayer = cloneDeep(getEmptyLeaguePlayer(new LeaguePlayerSeedInfo()))
  private editContacts: PlayerContact[] = []
  private editPracticeNightExclusions: PlayerPracticeNightExclusion[] = []
  private editMode = '' // maps to routename, used for watcher on edit template

  private upwId = '' //aught to be set by the router.
  private playerContactVolunteers: LeagueVolunteer[] = []
  private teamInfo: DivisionTeamInfo[] = []

  /***
   * Triggered on the edit to take care of the UC for looking up a prior athlete.
   */
  private searchName = '' //command delimited name to lookup.
  nameUpdated(first: string, last: string) {
    this.searchName = last + ',' + first
  }

  private imported = false
  async participantFound({ id, leagueID }: { id: PlayerInfoIDType; leagueID: string }) {
    await this.getTemplate({ id, importLeagueID: leagueID }) //update template
    this.imported = !!(leagueID && leagueID != this.upwId)
    this.reconcileTemplate()
  }
  /**
   * Do most of the environment scuttle here. If we don't have
   * a new, edited or cloned id then prepare it before moving on
   */
  @Watch('$route', { immediate: true, deep: true })
  async routeChange(to: Route) {
    this.imported = false
    this.editMode = to.name?.split('-')[0] ?? ''

    try {
      if (to?.params.id) {
        this.upwId = to.params.id
        //probably already loaded.
        //await this.loadProgramParticipants({ leagueId: to?.params.id })
      }
      if (to?.params?.pid) {
        try {
          this.setLastLeagueLoaded({ leagueID: to?.params.id })
          await this.setCurrentParticipantByID({
            id: parseInt(to.params.pid),
            typeProgramID: this.currentStoreProgram,
          })
          this.teamInfo = await teamsClient.retrieveTeamInfo({
            leagueID: to?.params.id ?? '',
            individualId: parseInt(to.params.pid),
          })
        } catch (error) {
          //no current participant, grab the template and start there.

          if (this.editMode == 'edit') {
            await this.getParticipant({ id: parseInt(to.params.pid) })
            this.teamInfo = await teamsClient.retrieveTeamInfo({
              leagueID: to?.params.id ?? '',
              individualId: parseInt(to.params.pid),
            })
          } else {
            //copy
            await this.getTemplate({ id: parseInt(to.params.pid), importLeagueID: to.params?.old_league })
            this.imported = !!(to.params?.old_league && to.params?.old_league != this.upwId)
          }
        }
      } else {
        //probably a new user, lets prospectively load a template.

        await this.getTemplate({})
      }
    } catch (e) {
      await this.$router.push(this.relativeRoute('list'))
      throw new ParticipantEditException(e.message)
    }

    this.reconcileTemplate()
    await this.decomposeTemplate()
  }

  /***
   * If a template is updated or refreshed, then integrate it in with
   * the data we know about.
   */
  reconcileTemplate() {
    if (this.participantTemplate && this.editParticipant) {
      this.editParticipant = cloneDeep(reconcileTemplate(this.editParticipant, this.participantTemplate))
      if (IsNewPlayer(this.editParticipant) && this.editParticipant.programs) {
        this.editParticipant.programs = this.editParticipant.programs.filter(
          (x) => x.typeProgramID == this.currentStoreProgram
        )
      }
    }
  }

  carpoolSelect() {
    this.carpoolSelectionOpen = true
  }

  get composedParticipant(): LeaguePlayer {
    return updateTemplateWithEditsNew(
      cloneDeep(this.participantTemplate),
      cloneDeep({
        player: this.editParticipant,
        contacts: this.editContacts,
        practiceNightExclusions: this.editPracticeNightExclusions,
        udfs: this.editUdfs,
      })
    )
  }

  coachLinkChanged({ coach, typeProgramID }: { coach: CoachSelect; typeProgramID: string }) {
    const prog = this.editParticipant.programs?.find((x) => x.typeProgramID == typeProgramID)
    if (prog) {
      if (coach.linkID == 0 && coach.firstName != '' && coach.lastName != '') {
        prog.pendingCoachLinkFirstName = coach.firstName
        prog.pendingCoachLinkLastName = coach.lastName
        prog.pendingCoachLinkIndividualID = coach.pendingLinkID
        prog.coachLinkIndividualID = coach.linkID
      } else {
        prog.pendingCoachLinkFirstName = ''
        prog.pendingCoachLinkLastName = ''
        prog.pendingCoachLinkIndividualID = 0
        prog.coachLinkIndividualID = coach.linkID
      }
    }
  }

  private get isEditMode() {
    return this.editMode === 'edit'
  }

  productsChanged({ products, typeProgramID }: { products: PlayerProduct[]; typeProgramID: string }) {
    const prog = this.editParticipant.programs?.find((x) => x.typeProgramID == typeProgramID)
    if (prog) {
      prog.products = products
    }
  }

  async decomposeTemplate() {
    this.reconcileTemplate()
    ;({
      player: this.editParticipant,
      contacts: this.editContacts,
      practiceNightExclusions: this.editPracticeNightExclusions,
      udfs: this.editUdfs,
    } = decomposeLeaguePlayer(this.editParticipant))

    this.playerContactVolunteers = []
    for (let i = 0; i < this.editContacts?.length; i++) {
      let x = this.editContacts[i]
      const c = await volunteersClient.retrieveVolunteer(this.upwId, x.individualID)
      if (c && c.leagueID > 0) {
        //c will basically always return something (as of now) so check leagueID to see if this person is a legit volunteer
        this.playerContactVolunteers.push(c)
      }
    }
  }

  /***
   * Possible lazy load of the template requires post-edit reconciliation.
   * @param newTemplate
   */
  @Watch('participantTemplate')
  function() {
    this.decomposeTemplate()
  }

  async saveAndContinue(e: MouseEvent) {
    e.stopPropagation()
    e.preventDefault()

    try {
      // will walk through volunteers and save any role changes.
      await this.updateVolunteerChanges()
      await this.upsert({
        leagueID: this.upwId,
        participant: this.composedParticipant,
        matched: this.imported,
      })
    } catch (e) {
      throw e
    }
    this.$router.push(this.relativeRoute('list'))
  }
  cancel(e: MouseEvent) {
    e.stopPropagation()
    e.preventDefault()
    this.setSkipParticipantRefresh({ flag: true })
    this.$router.push(this.relativeRoute('list'))
  }
}
