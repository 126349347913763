
































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import TextInput from '../../../../elements/TextInput.vue'
import { cloneDeep } from 'lodash'
import { PlayerCarpoolLink } from '@/GeneratedTypes/PlayerCarpoolLink'
import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import CarpoolSelectionModal from '@/components/CarpoolSelectionModal.vue'
import { getEmptyCarpoolLink } from '@/lib/support/models/PlayerCarpoolLink/data'
import { CarpoolInfo } from '@/lib/components/CarpoolSelectionModal/CarpoolGridColumns'

@Component({
  components: {
    TextInput,
    CarpoolSelectionModal,
  },
})
export default class CarpoolSelect extends Vue {
  @Prop({ type: Object, default: () => getEmptyCarpoolLink() })
  private value!: PlayerCarpoolLink
  private internalValue: PlayerCarpoolLink = getEmptyCarpoolLink()

  private carpoolSelectionOpen = false
  @Prop({ type: String, required: true, default: '' })
  private league!: string
  private editOpen = false
  @Prop({ type: Array, required: true, default: () => [] })
  participants!: LeaguePlayerInfo[]
  @Prop({ type: Number, required: true, default: 0 })
  playerID!: number

  /**
   * Coach has been selected by the select process.
   * @param participant_id
   */
  carpoolSelected(participant_id: number) {
    const participant = this.participants.find((x) => x.individualID == participant_id)
    if (participant) {
      this.volunteerFindToValueField(participant)
      this.input()
    }
  }

  get initialSearch(): string {
    if (this.internalValue.pendingLastName && this.internalValue.pendingFirstName) {
      return (this.internalValue.pendingLastName + ', ' + this.internalValue.pendingFirstName).trim()
    }

    if (this.internalValue.pendingLastName) {
      return this.internalValue.pendingLastName.trim()
    }

    return (this.internalValue.pendingFirstName ?? '').trim()
  }

  /**
   * return the id of the person that is not the player
   */
  get notMeLinkID(): number {
    if (this.internalValue.player1IndividualID == this.playerID) {
      return this.internalValue.player2IndividualID
    }
    return this.internalValue.player1IndividualID
  }

  get selectedName(): string {
    if (this.isCarpoolLinked) {
      const x = this.participants.find((y) => this.notMeLinkID == y.individualID)
      return x?.formattedNameFirstLast ?? 'n/a'
    }

    const x = this.participants.find((y) => this.internalValue.pendingIndividualID == y.individualID)
    if (x) {
      return x?.formattedNameFirstLast ?? 'n/a'
    }
    if (this.internalValue.pendingLastName) {
      return `${this.internalValue.pendingFirstName} ${this.internalValue.pendingLastName}`
    }
    return ''
  }

  /**
   * Convert the selected player to a player selection.
   * @param player
   */
  volunteerFindToValueField(player: LeaguePlayerInfo | null) {
    if (player) {
      this.internalValue.player2IndividualID = player.individualID
      this.internalValue.player1IndividualID = this.playerID
      this.internalValue.pendingLastName = ''
      this.internalValue.pendingFirstName = ''
      this.internalValue.pendingIndividualID = 0
    } else {
      this.internalValue = getEmptyCarpoolLink()
    }
  }

  pendingCarpoolLink(row: CarpoolInfo) {
    this.internalValue.player2IndividualID = 0
    this.internalValue.pendingIndividualID = row.individualID
    this.internalValue.player1IndividualID = this.playerID
    this.internalValue.pendingLastName = ''
    this.internalValue.pendingFirstName = ''
  }

  clear() {
    this.internalValue = getEmptyCarpoolLink()
    this.carpoolSelectionOpen = false
    this.input()
  }

  pending(row: CarpoolInfo) {
    this.pendingCarpoolLink(row)
    this.input()
    this.carpoolSelectionOpen = false
  }

  @Emit()
  input() {
    return this.internalValue
  }

  /**
   * Open the coach selection dialog.
   */
  carpoolSelect() {
    this.carpoolSelectionOpen = true
  }
  setInternalValue() {
    if (!this.value) {
      this.internalValue = getEmptyCarpoolLink()
    } else {
      this.internalValue = cloneDeep(this.value)
    }
  }
  @Watch('value', { immediate: true })
  valueChange() {
    this.setInternalValue()
  }
  openModal() {
    this.editOpen = true
  }

  get shouldShowWarning() {
    return (
      (!this.isCarpoolLinked && this.internalValue.pendingLastName) ||
      this.internalValue.pendingFirstName ||
      this.internalValue.pendingIndividualID
    )
  }
  get isCarpoolLinked() {
    return !!this.notMeLinkID
  }

  @Emit()
  update() {
    return this.internalValue
  }
}
