import { ContactInfo } from '@/GeneratedTypes/Partner/ListInfo/ContactInfo'
import { PlayerContact } from '@/GeneratedTypes/PlayerContact'
import { getPhoneByType } from '@/lib/support/models/PlayerContact/phoneutils'
import { PhoneTypeEnum } from '@/lib/support/models/IndividualPhone/data'

export function PlayerContactToContactInfo(invar: PlayerContact): ContactInfo {
  const rv: ContactInfo = {
    id: invar.individualID,
    alternatePhone: getPhoneByType(invar, PhoneTypeEnum.WORK).phoneNumber,
    alternatePhoneTypeID: PhoneTypeEnum.WORK,
    emailAddress: invar?.emailAddresses?.length ? invar.emailAddresses[0].emailAddress : '',
    emailValid: invar?.emailAddresses?.length ? invar.emailAddresses[0].valid : 'true',
    emailValidReason: invar?.emailAddresses?.length ? invar.emailAddresses[0].validityReason : '',
    firstName: invar.firstName,
    fullName: `${invar.firstName} ${invar.lastName}`,
    isFullTime: false,
    lastName: invar.lastName,
    preferredContactDayOfWeek: null,
    preferredContactMethod: PhoneTypeEnum.MOBILE,
    preferredContactTimeOfDay: null,
    primaryPhone: getPhoneByType(invar, PhoneTypeEnum.MOBILE).phoneNumber,
    primaryPhoneTypeID: PhoneTypeEnum.MOBILE,
    secondaryPhone: getPhoneByType(invar, PhoneTypeEnum.HOME).phoneNumber,
    secondaryPhoneTypeID: PhoneTypeEnum.HOME,
    trained: false,
    typePartnerRoleID: invar.typeRelationshipID, //not sure yet.
    yearsExperience: 0,
  }
  return rv
}
