












































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import TextInput from '@/elements/TextInput.vue'
import Loading from '@/elements/Loading.vue'
import InputLabel from '@/elements/InputLabel.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import DateInput from '@/elements/DateInput.vue'

import { LeaguePlayer } from '@/GeneratedTypes/LeaguePlayer'
import {
  getEmptyLeaguePlayer,
  IsNewPlayer,
  LeaguePlayerSeedInfo,
} from '@/lib/support/models/LeaguePlayer/data'

import SelectInput from '@/elements/SelectInput.vue'
import { getGrades } from '@/lib/support/models/UpwardTypes/UpwardGradeTypeID/grades'
import { getGradeTypeIDFromBirthDate, getDateRangeForGrades } from '@/services/gradeService'
import { LeagueAbstraction } from '@/models/LeagueAbstraction/LeagueAbstraction'
import * as leagueAbstraction from '@/store/leagueAbstraction'
import * as gradeStore from '@/store/gradeTypes'
import { UpwardGradeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGradeTypeID'
import dayjs from 'dayjs'

import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import { cloneDeep } from 'lodash'
import { isNullOrEmpty } from '@/lib/support/utilities/strings/isNullOrEmpty'

@Component({
  components: {
    RadioGroupInput,
    SelectInput,
    DateInput,
    Loading,
    TextInput,
    InputLabel,
    CheckboxInput,
  },
})
export default class ParticipantBasics extends Vue {
  @Prop({ type: Object, required: true })
  private value!: LeaguePlayer
  private editPlayer: LeaguePlayer = getEmptyLeaguePlayer(new LeaguePlayerSeedInfo())

  @Prop({ type: Array, required: false, default: () => [] })
  private gradesList!: string[]

  @Prop({ type: Object, required: false })
  private genderList!: object

  @Getter(leagueAbstraction.getterNames.isByAge, { namespace: leagueAbstraction.leagueAbstractionNamespace })
  private isByAge!: boolean

  @Getter(leagueAbstraction.getterNames.currentItem, {
    namespace: leagueAbstraction.leagueAbstractionNamespace,
  })
  private currentProgram!: LeagueAbstraction

  @Getter(gradeStore.getterNames.byUpwardTypeId, { namespace: gradeStore.namespace })
  private getUpwardGradeTypeID!: (typeID: string) => UpwardGradeTypeID | null

  private defaultGradeList = getGrades(false)
  private defaultGenderList = { M: 'Male', F: 'Female' }

  private hasBlurredFirstName = false
  private hasBlurredLastName = false
  private hasNotifiedBlur = false

  private calculatedAge = 0
  private internalBirthDate: Date | null = null
  private recalcGrade = false

  /**
   * Passed in grades take precedence over default grades
   */
  get grades() {
    if (this.gradesList.length) {
      return this.gradesList
    }
    return this.defaultGradeList
  }

  get overrideGradeList() {
    return this.grades.map((g) => this.getUpwardGradeTypeID(g))
  }

  /**
   * Passed in genders take precedence over default grades
   */
  get genders() {
    if (!this.genderList) return this.defaultGradeList
    return this.genderList
  }

  get formattedCutoffDate() {
    return this.currentProgram.ageCutoffDate
      ? dayjs(this.currentProgram.ageCutoffDate).format('MM/DD/YY')
      : '??'
  }

  get validDateRange() {
    const range = getDateRangeForGrades(
      this.grades[0],
      this.grades[this.grades.length - 1],
      this.isByAge ? this.currentProgram.ageCutoffDate : null
    )
    const fullRange = getDateRangeForGrades(
      'K3',
      '12th',
      this.isByAge ? this.currentProgram.ageCutoffDate : null
    )
    //as of this writing, for some reason the date-picket automatically subtracts a day from this date, so add it to get a proper range
    return {
      ...range,
      adjustedFromDate: dayjs(range.fromDate).add(1, 'day').toDate(),
      validationFromDate: dayjs(range.fromDate).subtract(1, 'day').format('MM/DD/YYYY'),
      validationToDate: dayjs(range.toDate).add(1, 'day').format('MM/DD/YYYY'),
      fullFromDateFormatted: dayjs(fullRange.fromDate).format('MM/DD/YYYY'),
      fullToDateFormatted: dayjs(fullRange.toDate).format('MM/DD/YYYY'),
    }
  }

  get calculatedGradeIsInList() {
    return this.grades.includes(this.editPlayer.typeGradeID)
  }

  /**
   * Tricky case, but on leaving a blurred field, new account, trigger a window to pop
   * up on an existing account. This is the notify, won't do it more than once, will only
   * do it if both fields are tickled in order.
   */
  @Watch('hasBlurredLastName')
  lastNameBlurred() {
    this.blurLookup()
  }
  @Watch('hasBlurredFirstName')
  firstNameBlurred() {
    this.blurLookup()
  }

  blurLookup() {
    // !this.hasNotifiedBlur && -- if you need this to go once and not more.
    if (
      IsNewPlayer(this.editPlayer) &&
      this.hasBlurredFirstName &&
      this.hasBlurredLastName &&
      this.editPlayer.firstName && //and first and last exist
      this.editPlayer.lastName
    ) {
      this.hasNotifiedBlur = true
      this.hasBlurredLastName = false
      this.hasBlurredFirstName = false

      this.$emit('nameUpdated', this.editPlayer.firstName, this.editPlayer.lastName)
    }
  }

  /**
   * Send parent the new LeaguePlayer
   */
  fireOnChange() {
    //console.log('fireOnChange', this.editPlayer)
    this.$emit('input', this.editPlayer)
  }

  // reflect changes passed from parent into the edit structures.
  @Watch('value', { immediate: true })
  function() {
    //console.log('value changed', this.value.typeGradeID, this.value.birthDate, this.internalBirthDate)
    this.recalcGrade = !isNullOrEmpty(this.editPlayer.typeGradeID) && isNullOrEmpty(this.value.typeGradeID)
    this.editPlayer = cloneDeep(this.value)
    this.editPlayer.gradeIsOverridden =
      this.editPlayer.gradeIsOverridden == null ? false : this.editPlayer.gradeIsOverridden
    this.sourceBirthDateChanged()
  }

  @Watch('internalBirthDate', { immediate: true })
  birthDateChanged() {
    if (this.isByAge) {
      const typeGradeID = getGradeTypeIDFromBirthDate(
        this.currentProgram.ageCutoffDate,
        this.internalBirthDate
      )
      this.calculatedAge = typeGradeID?.ageByCutoff ?? 0
      if (!this.editPlayer.gradeIsOverridden) {
        this.editPlayer.typeGradeID = typeGradeID?.upwardTypeID ?? ''
      }
      //console.log(typeGradeID, this.calculatedAge, this.editPlayer.typeGradeID)
    }
    //console.log('birthDateChanged - updating editPlayer and firing', this.internalBirthDate)
    this.editPlayer.birthDate = this.internalBirthDate
    this.fireOnChange()
  }

  @Watch('editPlayer.birthDate')
  sourceBirthDateChanged() {
    if (
      !dayjs(this.internalBirthDate ?? undefined).isSame(dayjs(this.editPlayer.birthDate ?? undefined), 'day')
    ) {
      //prevent infinite loops on birthdate changes
      this.internalBirthDate = this.editPlayer.birthDate
    } else {
      //if this was called directly then this was a change of value
      //see if it was a clearing of the grade (ex: when Add Existing is used) and if so, call the birthDateCahnged to recalc the grade
      if (this.recalcGrade) {
        this.birthDateChanged()
      }
    }
  }

  @Watch('editPlayer.gradeIsOverridden')
  gradeIsOverriddenChanged() {
    //console.log('gradeIsOverriddenChanged')
    if (this.isByAge && !this.editPlayer.gradeIsOverridden) {
      this.birthDateChanged()
    }
  }
}
