















































import { defineComponent, computed, ref, watch, onMounted, PropType } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { ContactInfo } from '@/GeneratedTypes/Partner/ListInfo/ContactInfo'
import { PlayerContactToContactInfo } from '@/lib/support/models/ContactInfo/PlayerContactToContactInfo'
import {
  getEmptyPlayerContact,
  decrementingPlayerContactIDSeedStrategy,
  isCompleteContact,
} from '@/lib/support/models/PlayerContact/data'
import { PlayerContact } from '@/GeneratedTypes/PlayerContact'
import InnerContact from '@/components/Contact/InnerContact.vue'
import RoleElement from '@/elements/css/RoleElement.vue'
import { RoleElementType } from '@/models/RoleElement/RoleElement'

export default defineComponent({
  name: 'ContactCardWithRoles',
  components: {
    InnerContact,
    RoleElement,
  },
  props: {
    contact: {
      type: Object as PropType<PlayerContact>,
      required: true,
      default: () => getEmptyPlayerContact(decrementingPlayerContactIDSeedStrategy()),
    },
    roles: { required: true, default: () => [], type: Array as PropType<RoleElementType[]> },
    deleteEnabled: { required: false, default: true, type: Boolean },
  },
  setup(props, ctx) {
    const editContact = ref<PlayerContact>(props.contact)

    const address = computed(() => (props.contact.addresses?.length ? props.contact.addresses[0] : null))

    const addressLine1 = computed(() => {
      if (!address.value) {
        return ''
      }

      return [address.value.street1, address.value.street2].filter((s) => s && s.trim()).join(', ')
    })

    const addressLine2 = computed(() => {
      if (!address.value) {
        return ''
      }

      const cityState = [address.value.subdivision1, address.value.subdivision2]
        .filter((s) => s && s.trim())
        .join(', ')

      return `${cityState} ${address.value.postalCode}`
    })

    const innerContact = computed((): ContactInfo => PlayerContactToContactInfo(editContact.value))

    /**
     * maintain an internal state that reflects our external contact.
     */
    function copyToEditContact() {
      editContact.value = cloneDeep(props.contact)
    }

    watch(
      () => props.contact,
      () => {
        copyToEditContact()
      },
      { deep: true }
    )

    function addRoles() {
      ctx.emit('addRoles', props.contact)
    }

    function removeRole(role: number) {
      ctx.emit('removeRole', props.contact, role)
    }

    const canAddRoles = computed(() => isCompleteContact(editContact.value))

    onMounted(() => {
      copyToEditContact()
    })

    return {
      innerContact,
      addressLine1,
      addressLine2,
      removeRole,
      canAddRoles,
      addRoles,
    }
  },
})
