





































































































import Vue from 'vue'
import { cloneDeep, uniq } from 'lodash'
import ContactEditModal from '@/components/ContactEditModal.vue'
import {
  getEmptyPlayerContact,
  decrementingPlayerContactIDSeedStrategy,
  PlayerContactSeed,
  PlayerContactTypeEnum,
} from '@/lib/support/models/PlayerContact/data'
import { PlayerContact } from '@/GeneratedTypes/PlayerContact'
import { Prop, Component, Watch, Emit } from 'vue-property-decorator'
import ContactCardWithRoles from '@/components/ContactCardWithRoles.vue'
import { addNewContactToList } from '@/views/Programs/Participants/vues/ext/contacts'
import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue'
import { PlayerInfoIDType } from '@/lib/support/models/LeaguePlayerInfo/data'
import ContactEditForm from '@/components/ContactEditForm.vue'
import {
  getContactValidationType,
  isFullContactValidationType,
} from '@/lib/support/models/PlayerContact/validation_utils'
import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'
import { RoleElementType } from '@/models/RoleElement/RoleElement'
import ModalMultiSelect from '@/components/ModalMultiSelect.vue'
import SelectInput from '@/elements/SelectInput.vue'
import { LeagueProgram } from '@/GeneratedTypes/LeagueProgram'
import { League } from '@/GeneratedTypes/League'
import { getEmptyLeague } from '@/lib/support/models/League/data'

import { getEmptyLeagueCoach } from '@/lib/support/models/LeagueCoach/data'
import { LeagueCoach } from '@/GeneratedTypes/LeagueCoach'
import { LeagueVolunteerRole } from '@/GeneratedTypes/LeagueVolunteerRole'
import { TypeLeagueVolunteerRole } from '@/GeneratedTypes/TypeLeagueVolunteerRole'
import {
  getGenderFromRole,
  PlayerContactToLeagueVolunteer,
} from '@/lib/support/models/LeagueVolunteer/PlayerContactToLeagueVolunteer'
import VolunteerOptionPanel from '@/views/Programs/Volunteers/vues/VolunteerOptionPanel.vue'
import { ContactRoleEditType } from '@/models/ParticipantUI/ContactRoleEditType'

const NEW_CONTACT = -9e4

@Component({
  components: {
    ContactEditForm,
    ConfirmDeleteModal,
    ContactCardWithRoles,
    ContactEditModal,
    ModalMultiSelect,
    SelectInput,
    VolunteerOptionPanel,
  },
})
export default class ParticipantsContacts extends Vue {
  private editContact: PlayerContact = getEmptyPlayerContact(new PlayerContactSeed())

  private editedContactIndex = NEW_CONTACT
  private contactModalOpen = false
  @Prop({ type: Array, required: true, default: () => [] })
  private readonly contacts!: PlayerContact[]
  private editContacts: PlayerContact[] = []
  //** list of volunteers since player contacts does not have roles.
  @Prop({ type: Array, required: true, default: () => [] })
  private readonly volunteers!: LeagueVolunteer[]

  @Prop({ type: Array, required: true, default: () => [] })
  private contactRoleChanges!: ContactRoleEditType[]

  @Prop({ type: Array, required: true, default: () => [] })
  private programs!: LeagueProgram[]
  @Prop({ type: Object, required: true, default: () => getEmptyLeague() })
  private league!: League

  @Prop({ type: Boolean, required: false, default: false })
  private readonly showInitialEditForm!: boolean

  @Prop({ type: Object, required: false })
  private genderList!: object

  private deleteConfirmShowing = false
  private deleteID: PlayerInfoIDType | null = null

  private selectOpen = false
  private roleContact: LeagueVolunteer | null = null
  private coachPreferences: LeagueCoach = getEmptyLeagueCoach()
  private roles: LeagueVolunteerRole[] = []
  private leagueRoles: TypeLeagueVolunteerRole[] = []
  private gender = ''

  private get editVolunteerPrograms() {
    return uniq([
      ...(this.coachPreferences.programs ?? []).map((x) => x.typeProgramID),
      ...this.programs.map((x) => x.typeProgramID),
    ])
  }

  //**signal that add roles was clicked
  addRoles(c: PlayerContact) {
    this.editContact = cloneDeep(c)
    this.roleContact = cloneDeep(
      this.volunteers.find((x) => x.individualID == c.individualID) ?? PlayerContactToLeagueVolunteer(c)
    )
    this.coachPreferences = cloneDeep(this.roleContact?.coachPreferences ?? getEmptyLeagueCoach())
    this.roles = cloneDeep(this.roleContact.roles ?? [])
    this.gender = this.roleContact?.gender ?? this.genderFromRole
    const o = this.contactRoleChanges.find((x) => x.id == c.individualID)

    if (o) {
      this.coachPreferences = cloneDeep(o.coachPrefs)
      this.roles = cloneDeep(o.roles)
    }

    this.selectOpen = true
  }
  /**
   * Provides the gender from the contact role or blank if undetermined
   */
  get genderFromRole() {
    return getGenderFromRole(this.editContact.typeRelationshipID as PlayerContactTypeEnum)
  }

  @Emit()
  roleListUpdate() {
    return {
      id: this.roleContact?.individualID,
      roles: this.roles,
      coachPrefs: this.coachPreferences ?? getEmptyLeagueCoach(),
      gender: this.gender,
    }
  }

  //**signal that a role was removed
  removeRole(c: PlayerContact, role: number) {
    this.roleContact = cloneDeep(
      this.volunteers.find((x) => x.individualID == c.individualID) ?? PlayerContactToLeagueVolunteer(c)
    )
    this.coachPreferences = this.roleContact?.coachPreferences ?? getEmptyLeagueCoach()
    this.roles = cloneDeep(this.actualRolesFor(c.individualID).filter((x) => x.roleID != role) ?? [])
    this.roleListUpdate()
  }

  get currentRoleIds() {
    return this.roles?.map((x) => x.roleID) ?? []
  }
  /**
   * Update this.roles based on ids passed in
   */
  filterRoles(ids: number[]) {
    let roles = cloneDeep(this.roles)

    ids.forEach((id) => {
      if (roles.findIndex((y) => y.roleID == id) < 0) {
        roles.push({
          roleID: id,
          approved: false,
          udFs: [],
          authorizedExpireDate: new Date(),
          upwardAuthInvitationID: '',
        })
      }
    })

    // filter out anything that is "not in" ids
    this.roles = roles.filter((x) => ids.findIndex((y) => y == x.roleID) >= 0)
  }

  updateSelectedRoles(ids: number[]) {
    this.filterRoles(ids)
  }

  sendSelectedRoles(ids: number[]) {
    this.filterRoles(ids)
    this.roleListUpdate()
  }

  /**
   * Responds to role detail updates
   */
  updateRoleDetails(coachPrefs: LeagueCoach, roles: LeagueVolunteerRole[]) {
    this.roles = cloneDeep(roles)
    this.coachPreferences = cloneDeep(coachPrefs)
  }

  copyToInternalContact(c: PlayerContact) {
    this.editedContactIndex = 0
    this.updateContact(c)
    return
  }

  actualRolesFor(id: number): LeagueVolunteerRole[] {
    let roles: LeagueVolunteerRole[] = []
    const q = this.contactRoleChanges.find((x) => x.id == id)
    if (q) {
      roles = cloneDeep(q.roles)
    } else {
      const c = this.volunteers.find((x) => x.individualID == id)
      if (c) {
        roles = cloneDeep(c.roles ?? [])
      }
    }
    return roles
  }

  /**
   * Grab roles for a contact, including roles pending write
   */
  rolesFor(id: number) {
    return (
      this.actualRolesFor(id)?.map((x) => ({
        id: x.roleID,
        description: this.league.roles?.find((y) => y.id == x.roleID)?.description,
      })) ?? []
    )
  }

  get visibleContacts() {
    return this.editContacts
  }

  /***
   * New Contact button clicked
   */
  addContact() {
    this.editedContactIndex = NEW_CONTACT
    this.editContact = getEmptyPlayerContact(decrementingPlayerContactIDSeedStrategy())
    this.contactModalOpen = true
  }
  editContactClick(a: PlayerContact) {
    const index = this.editContacts.findIndex((x) => x.individualID == a.individualID)
    if (index >= 0) {
      this.editedContactIndex = index
      this.editContact = cloneDeep(this.editContacts[index])

      this.contactModalOpen = true
    }
  }

  /**
   * called by confirm box
   * prior to confirmed deleteID is set.
   */
  deleteConfirmed() {
    this.editContacts = this.editContacts.filter((x) => x.individualID != this.deleteID)
    this.update()
  }

  deleteContactClick(a: PlayerContact) {
    this.deleteID = a.individualID
    this.deleteConfirmShowing = true
  }

  get contactsValid() {
    return this.hasFullContact && this.hasEmergencyContact
  }

  update() {
    if (this.contactsValid) {
      this.$emit('update', this.editContacts)
    }
  }

  updateContact(contact: PlayerContact) {
    //if we don't have an emergency contact, this will be it
    if (!this.hasEmergencyContact) {
      contact.isEmergencyContact = true
    }

    // if this contact is marked as an emergency contact, deselect the other.
    if (contact.isEmergencyContact) {
      this.editContacts = [...this.editContacts.map((x) => ({ ...x, isEmergencyContact: false }))]
    }

    if (this.editedContactIndex === NEW_CONTACT) {
      //case where we are dealing with the template
      this.editContacts = [...addNewContactToList(this.editContacts, { ...contact })]
    } else {
      this.editContacts.splice(this.editedContactIndex, 1, { ...contact })
    }
    this.update()
  }

  get hasEmergencyContact() {
    return this.editContacts.some((x) => x.isEmergencyContact)
  }
  get hasFullContact() {
    return this.editContacts.some((x) => isFullContactValidationType(getContactValidationType(x)))
  }

  @Watch('contacts')
  copyContactPropToEdit() {
    if (this.showInitialEditForm && !this.contacts?.length) {
      this.editContacts = [
        //default to EC on first contact when edit form is opened.
        { ...getEmptyPlayerContact(decrementingPlayerContactIDSeedStrategy()), isEmergencyContact: true },
        ...this.contacts,
      ]
    } else {
      this.editContacts = [...this.contacts]
    }
  }

  @Watch('league')
  setRoleValues() {
    this.leagueRoles = cloneDeep(this.league.roles ?? [])
  }

  get allRoleValues(): RoleElementType[] {
    return this.leagueRoles
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((x) => ({
        id: x.id,
        description: x.description ?? '',
      }))
  }

  mounted() {
    this.copyContactPropToEdit()
    this.setRoleValues()
  }
}
