import { PlayerContact } from '@/GeneratedTypes/PlayerContact'
import { cloneDeep } from 'lodash'

/***
 * This will add a contact to a list of contacts.
 * -- There is a funny case where the template
 *   passes a blank contact, we want to override that with the first contact added.
 * @param list
 * @param newContact
 */
export function addNewContactToList(list: PlayerContact[], newContact: PlayerContact): PlayerContact[] {
  if (list.length == 1) {
    //stand in for is this a valid contact, or just a template contact
    if (!list[0].firstName) {
      list = [cloneDeep(newContact)]
    } else {
      list.push(cloneDeep(newContact))
    }
  } else {
    //lists with more than one contact
    list.push(cloneDeep(newContact))
  }
  return list
}
