import { PlayerCarpoolLink } from '@/GeneratedTypes/PlayerCarpoolLink'

export function getEmptyCarpoolLink(): PlayerCarpoolLink {
  return {
    id: 0,
    pendingFirstName: '',
    pendingIndividualID: 0,
    pendingLastName: '',
    player1IndividualID: 0,
    player2IndividualID: 0,
  }
}
