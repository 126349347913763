import { GridColumn, GridColumnAlignEnum } from '@/models/Grid/GridColumn'

export const EvaluationGridColumns: GridColumn[] = [
  {
    field: 'activityText',
    headerText: 'Activity',
    textAlign: '',
    width: 200,
    format: '',
  } as GridColumn,
  {
    field: 'activityValue',
    headerText: 'Score',
    textAlign: GridColumnAlignEnum.RIGHT,
    width: 0,
    //    template: ScoreTemplate,
  } as GridColumn,
]
