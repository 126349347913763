/**
 * Utility function to patch, compose and decompose LeaguePlayers templates
 */

import { LeaguePlayer } from '@/GeneratedTypes/LeaguePlayer'

import { PlayerContact } from '@/GeneratedTypes/PlayerContact'

import { PlayerPracticeNightExclusion } from '@/GeneratedTypes/PlayerPracticeNightExclusion'
import { PlayerUDFValue } from '@/GeneratedTypes/PlayerUDFValue'
import { cloneDeep } from 'lodash'

/***
 * These are useful for the participant edit interface, maybe others.
 */
export interface DecomposedPlayerArgs {
  player: LeaguePlayer
  contacts: PlayerContact[]
  practiceNightExclusions: PlayerPracticeNightExclusion[]
  udfs: PlayerUDFValue[]
}

/**
 * the API occasionally passes contacts back with 11-digit phone numbers, and without EC set.
 * we need to remove leadings ones on phone numbers and set EC to first contact in those cases
 */
function scrubContacts(contacts: PlayerContact[]) {
  const pc = cloneDeep(contacts)
  if (pc.length) {
    //require an emergency contact
    if (!pc.some((x) => x.isEmergencyContact)) {
      pc[0].isEmergencyContact = true
    }
    for (let i = 0; i < pc.length; i++) {
      if (pc[i].phoneNumbers?.length) {
        pc[i].phoneNumbers =
          cloneDeep(
            pc[i].phoneNumbers?.map((x) => ({
              ...x,
              phoneNumber:
                // chop off the initial one if the incoming number has one.
                x.phoneNumber.length > 10 && x.phoneNumber[0] == '1'
                  ? x.phoneNumber.substr(1)
                  : x.phoneNumber,
            }))
          ) ?? []
      }
    }
  }
  return pc
}

/***
 * All assumptions about where form elements are coming from are made here.
 *
 * @param input incoming league player
 * @return decomposed form members from a a leagueplayer
 */
export function decomposeLeaguePlayer(input: LeaguePlayer): DecomposedPlayerArgs {
  return {
    player: input, //these are the basic top-level fields like name
    practiceNightExclusions: input.practiceNightExclusions?.length ? input.practiceNightExclusions : [],
    contacts: scrubContacts(input.contacts ?? []),
    udfs: input.udFs ?? [],
  }
}

/***
 * Combine the selected info thing and the template "loaded"
 * keeping the values under edit alive that don't need to be changed.
 * @param edit
 * @param template
 */

export function reconcileTemplate(edit: LeaguePlayer, template: LeaguePlayer): LeaguePlayer {
  return cloneDeep(template)
}
