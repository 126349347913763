














































import { defineComponent, ref, watch, computed, onMounted, PropType } from '@vue/composition-api'
import { getProps, useModalValue } from '@/composables/ModalValue'

import SearchModal from '@/elements/SearchModal.vue'
import { CarpoolInfo, headers } from '@/lib/components/CarpoolSelectionModal/CarpoolGridColumns'
import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import store from '@/store'

export default defineComponent({
  name: 'CarpoolSelectionModal',
  components: {
    SearchModal,
  },
  props: {
    participants: { type: Array as PropType<LeaguePlayerInfo[]>, required: true, default: () => [] },
    selected: { required: true, type: Number, default: 0 },
    linkID: { type: Number, required: false, default: 0 },
    initialSearch: { type: String, required: false, default: '' },
    ...getProps(),
  },
  setup(props, ctx) {
    const { isModalOpen } = useModalValue(props, ctx)
    const currentParticipant = computed(() => store.getters.participants.participantUnderEdit)

    const showWarning = ref(false)
    const selectedRow = ref<CarpoolInfo | null>(null)

    const search = ref('')

    function searchSelected(p: LeaguePlayerInfo) {
      ctx.emit('select', p.individualID)
    }

    const selectionData = computed((): CarpoolInfo[] => {
      const searchTerms = search.value.split(' ')
      return searchData.value.filter(
        (x) =>
          (search.value && stringIncludes(x.name ?? '', searchTerms)) ||
          props.linkID == x.individualID ||
          props.selected == x.individualID
      )
    })

    function stringIncludes(stringToSearch: string, searchTerms: string[]): boolean {
      stringToSearch = stringToSearch.toLowerCase()
      for (let i = 0; i < searchTerms.length; i++) {
        if (!stringToSearch.includes(searchTerms[i].toLowerCase())) {
          return false
        }
      }
      return true
    }

    function pending() {
      ctx.emit('pending', selectedRow.value)
    }

    watch(
      () => isModalOpen.value,
      () => {
        showWarning.value = false
      }
    )

    function messageCheck(a: CarpoolInfo | null) {
      selectedRow.value = a
      const hasConnecion = `${a?.connectedFistName}${a?.connectedLastName}`.length > 0
      showWarning.value = hasConnecion && !a?.selected
    }

    const searchData = computed(() => {
      if (!props.participants || !currentParticipant.value) return []
      const data = props.participants.filter((p) => {
        // remove player under edit and filter on program
        const isParticipantUnderEdit = p.individualID == currentParticipant.value.individualID
        return !isParticipantUnderEdit
      })

      return data.map((x) => {
        return {
          name: `${x.lastName}, ${x.firstName}`,
          first: x.firstName,
          last: x.lastName,
          gender: x.gender ?? '',
          individualID: x.individualID,
          selected: x.individualID == props.selected,
          connectedFistName: x.actualCarpoolLinkFirstName,
          connectedLastName: x.actualCarpoolLinkLastName,
          prettyConnectedTo: `${x.actualCarpoolLinkFirstName} ${x.actualCarpoolLinkLastName}`,
          grade: x.typeGradeID,
        } as CarpoolInfo
      })
    })

    const searchActive = ref(false) //not showing initially

    /***
     * Callback on the SearchModal
     * @param s
     */
    function updateSearch(s: string) {
      if (!searchActive.value) {
        searchActive.value = !!s
        search.value = s
        searchActive.value = false
      }
    }

    onMounted(() => {
      search.value = props.initialSearch
    })

    return {
      isModalOpen,
      selectionData,
      headers,
      searchActive,
      updateSearch,
      searchSelected,
      messageCheck,
      selectedRow,
      showWarning,
      currentParticipant,
      pending,
    }
  },
})
