/***
 * Converts Sunday to 0, Monday to 1, etc... based on the UpwardID
 * @constructor
 */
import { ArgumentRuntimeException } from '@/lib/common/exceptions/ArgumentRuntimeException'

export function UpwardIDToNumber(UpwardID: string) {
  switch (UpwardID) {
    case 'Sunday':
      return 0
    case 'Monday':
      return 1
    case 'Tuesday':
      return 2
    case 'Wednesday':
      return 3
    case 'Thursday':
      return 4
    case 'Friday':
      return 5
    case 'Saturday':
      return 6
  }
  throw new ArgumentRuntimeException(`UpwardIDToNumber, ${UpwardID} not valid for a weekday`)
}
