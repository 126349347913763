import { PlayerPayment } from '@/GeneratedTypes/PlayerPayment'
import { PlayerPaymentBreakout } from '@/GeneratedTypes/PlayerPaymentBreakout'

export function getEmptyPlayerPayment(seed?: PlayerPaymentSeed): PlayerPayment {
  return {
    paymentInfo: '',
    breakout: [getEmptyBreakout()],
    typePaymentMethodID: '',
    paymentDate: new Date(),
    paymentID: seed?.paymentID ?? 0,
  }
}

export class PlayerPaymentSeed {
  paymentID = 0
}

let seedID = -1

/**
 * Create a strategy where payment IDs are unique and negative for
 * use as uncommitted seeds.
 */

export function decrementingPaymentSeed(): PlayerPaymentSeed {
  const c = new PlayerPaymentSeed()
  c.paymentID = seedID
  seedID--
  return c
}

function getEmptyBreakout(): PlayerPaymentBreakout {
  return {
    amount: 0,
    breakoutID: 0,
    note: '',
  }
}
