


















import { defineComponent, ref, watch, PropType } from '@vue/composition-api'

import { EvaluationGridColumns } from '@/views/Programs/Participants/vues/ext/EvaluationGridColumns'
import SimpleGrid from '@/elements/SimpleGrid.vue'
import { PlayerEvaluation } from '@/GeneratedTypes/PlayerEvaluation'
import PlayerEvaluationEditModal from '@/components/PlayerEvaluationEditModal.vue'
import { cloneDeep } from 'lodash'

export default defineComponent({
  name: 'EvaluationDisplay',
  components: {
    PlayerEvaluationEditModal,
    SimpleGrid,
  },
  props: {
    value: { type: Array as PropType<PlayerEvaluation[]>, default: [] },
  },
  setup(props, ctx) {
    const columns = ref(EvaluationGridColumns)
    const internalEvaluationData = ref<PlayerEvaluation[]>([])

    const editOpen = ref(false)

    function setInternalEvalData() {
      if (!props.value) {
        internalEvaluationData.value = []
      } else {
        internalEvaluationData.value = cloneDeep([...props.value].sort((a, b) => a.sortOrder - b.sortOrder))
      }
    }

    watch(
      () => props.value,
      () => {
        setInternalEvalData()
      },
      { immediate: true }
    )

    function openModal() {
      editOpen.value = true
    }

    function updateEvaluation(evaluation: PlayerEvaluation[]) {
      internalEvaluationData.value = cloneDeep([...evaluation])
      ctx.emit('input', internalEvaluationData.value)
    }

    return {
      columns,
      internalEvaluationData,
      editOpen,
      openModal,
      updateEvaluation,
    }
  },
})
