import { PlayerPayment } from '@/GeneratedTypes/PlayerPayment'
import { cloneDeep } from 'lodash'

export function paymentSum(payment: PlayerPayment) {
  let sum = 0
  payment?.breakout?.forEach((x) => {
    sum += x.amount
  })
  return sum
}

export function paymentNote(payment: PlayerPayment) {
  return payment?.breakout?.[0].note || payment.paymentInfo || ''
}

export function composePayment(
  payment: PlayerPayment,
  amount: number,
  note: string,
  method: string,
  date: Date
): PlayerPayment {
  return cloneDeep({
    ...payment,
    paymentDate: date,
    typePaymentMethodID: method,
    breakout: [
      {
        note: note,
        breakoutID: 0,
        amount: amount,
      },
    ],
  })
}
