import { PlayerContact } from '@/GeneratedTypes/PlayerContact'
import {
  getEmptyPhone,
  NameToType,
  PhoneSeedInfo,
  PhoneTypeEnum,
} from '@/lib/support/models/IndividualPhone/data'
import { IndividualPhone } from '@/GeneratedTypes/IndividualPhone'

/**
 * get the first phone number by the given type, if no such phone then get an empty phone
 * @param contact {PlayerContact} player contact to search
 * @param pte {PhoneTypeEnum} type of phone
 * @return {IndividualPhone} phone retrieved
 */
export function getPhoneByType(contact: PlayerContact, pte: PhoneTypeEnum) {
  // we create a new phone in case the one requested is not present
  const newPhone = getEmptyPhone(new PhoneSeedInfo())
  newPhone.typePhoneID = pte

  if (contact.phoneNumbers?.length) {
    const pt = NameToType(pte)
    return contact.phoneNumbers.find((x) => x.typePhoneID == pt) ?? newPhone
  }
  return newPhone
}

/***
 * set a phone on a contact, matches by the type and replaces the phone in the contact
 * UNLESS there is no phone, then add it to the existing phones. If we have lots of the
 * same type of phone on a contact, then we need to tweak this logic.
 * @param contact {PlayerContact} player contact to update
 * @param {IndividualPhone} phone phone to update
 * @return {PlayerContact} contact with updated phone
 */
export function setPhone(contact: PlayerContact, phone: IndividualPhone) {
  // we create a new phone in case the one requested is not present

  if (contact.phoneNumbers?.length) {
    const cI = contact.phoneNumbers.findIndex((x) => x.typePhoneID == phone.typePhoneID)
    if (cI >= 0) {
      contact.phoneNumbers[cI] = phone
    } else {
      contact.phoneNumbers.push(phone)
    }
  } else {
    contact.phoneNumbers = [phone]
  }
  return contact
}
