import { DataTableHeader } from 'vuetify/types'

export const headers = [
  { text: 'Name', value: 'name' },
  { text: 'Gender', value: 'gender' },
  { text: 'Existing Link', value: 'prettyConnectedTo' },
] as DataTableHeader[]

export interface CarpoolInfo {
  name: string
  first: string
  last: string
  gender: string
  individualID: number
  selected: boolean
  connectedTo: string
  grade: string
  prettyConnectedTo: string
  connectedFistName: string
  connectedLastName: string
}
