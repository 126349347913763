import { GridColumn, GridColumnAlignEnum } from '@/models/Grid/GridColumn'
import dayjs from 'dayjs'
import Vue from 'vue'

//
const DateTemplate = () => ({
  template: Vue.component('DateTemplate', {
    props: { data: {} },
    methods: {
      formatteddate() {
        //@ts-ignore -- templates don't have types.
        return dayjs(this.data?.date).format('MM/DD/YY')
      },
    },
    template: '<div>{{this.formatteddate()}}</div>',
  }),
})

const AmountTemplate = () => ({
  template: Vue.component('AmountTemplate', {
    props: { data: {} },

    template: '<div class="float-right">${{data.amount.toFixed(2)}}</div>',
  }),
})
function deleteTemplate(
  editEvent: (p: Record<symbol, unknown>) => void,
  deleteEvent: (p: Record<symbol, unknown>) => void
) {
  return () => ({
    template: Vue.component('deleteTemplate', {
      props: { data: {} },
      methods: {
        localDelete() {
          //@ts-ignore - magical method in a vue template
          deleteEvent(this.data)
        },
        localEdit() {
          //@ts-ignore - magical method in a vue template
          editEvent(this.data)
        },
      },
      template:
        '<div><i @click="localEdit" class="fas fa-edit"></i>&nbsp<i @click="localDelete" class="fas fa-trash-alt"></i></div>',
    }),
  })
}

export function getPaymentColumns(editEvent: (p: any) => void, deleteEvent: (p: any) => void): GridColumn[] {
  return [
    {
      field: 'date',
      headerText: 'Date',
      textAlign: '',
      width: 60,
      format: '',
      template: DateTemplate,
    } as GridColumn,
    {
      field: 'type',
      headerText: 'Type',
      textAlign: '',
      width: 60,
    } as GridColumn,
    {
      field: 'amount',
      headerText: 'Amount',
      textAlign: GridColumnAlignEnum.LEFT,
      template: AmountTemplate,
      width: 60,
    } as GridColumn,
    {
      field: 'note',
      headerText: 'Note',
      textAlign: GridColumnAlignEnum.LEFT,
    } as GridColumn,
    {
      field: '',
      headerText: '',
      textAlign: GridColumnAlignEnum.RIGHT,
      width: 0,
      template: deleteTemplate(editEvent, deleteEvent),
    } as GridColumn,
  ]
}
